import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import { ListItemIcon, MenuItem, styled, Tooltip } from '@mui/material';
import { useLatestFunction } from '@sitedrive/design-system';
import { produce } from 'immer';

import { type HeaderProjectQuery, useHeaderProjectQuery } from '~/@generated/graphql';
import { routes, useNavigateAndKeepParams } from '~/config/routes';
import { useProjectId } from '~/store';

import { IconFullStackedBarChart, IconMoreHoriz } from '../Icons/icons';
import Menu from '../Menu';

import { IconNavButton, NavButton, StyledNavButton } from './Header.NavButtons';

const Container = styled('div')`
  display: flex;
  align-items: stretch;
  align-self: stretch;
  flex: 1;
  min-width: 0;
`;

const Cont = styled('div')`
  display: flex;
  align-items: stretch;
  align-self: stretch;
  flex: 1;
  min-width: 0;
`;

const HiddenNavButton = styled(StyledNavButton)`
  position: fixed;
  left: -1000px;
  visibility: hidden;
`;

function MeasuredNavButton({
  onRender,
  ...props
}: React.ComponentProps<typeof HiddenNavButton> & { onRender: (width: number) => void; currentWidth: number }) {
  const [ref, measures] = useMeasure();

  const latestOnRender = useLatestFunction(onRender);

  useEffect(() => {
    latestOnRender(measures.width);
  }, [measures.width, latestOnRender]);

  return <HiddenNavButton ref={ref} {...props} />;
}

function Component({ project }: { project: NonNullable<HeaderProjectQuery['project']> }) {
  const { t } = useTranslation();

  const projectId = useProjectId();
  const { pathname } = useLocation();
  const navigate = useNavigateAndKeepParams();

  const menu = Menu.useMenu({ id: 'schedules-menu-button' });

  const [ref, containerMeasures] = useMeasure();

  const scheduleViews = project.schedules.flatMap((schedule) => schedule?.schedule_views ?? []);

  const [widths, setWidths] = useState<number[]>([]);

  function getLastFittingIndex() {
    let space = containerMeasures.width;

    for (let i = 0; i < widths.length; i += 1) {
      space -= widths[i] ?? 0;
      if (space < 0) {
        return i;
      }
    }
    return widths.length;
  }

  const lastFittingIndex = getLastFittingIndex();

  const fitting = scheduleViews.slice(0, lastFittingIndex);
  const remaining = scheduleViews.slice(lastFittingIndex);

  return (
    <Container>
      <NavButton showArrow to={routes.sitePage(project.site_id).projects(projectId).scheduleViews()}>
        {project.name}
      </NavButton>
      <Cont ref={ref}>
        {fitting.map((scheduleView) => (
          <NavButton key={scheduleView.id} to={routes.schedulePage(scheduleView.id)}>
            {scheduleView.name}
          </NavButton>
        ))}
        {scheduleViews.map((scheduleView, index) => (
          <MeasuredNavButton
            key={scheduleView.id}
            currentWidth={containerMeasures.width}
            onRender={(width) => {
              setWidths((prev) =>
                produce(prev, (draft) => {
                  // eslint-disable-next-line no-param-reassign
                  draft[index] = width;
                }),
              );
            }}
          >
            {scheduleView.name}
          </MeasuredNavButton>
        ))}

        {remaining.length > 0 && (
          <Tooltip title={t('Header.ProjectMenu.title')}>
            <IconNavButton {...menu.anchorProps}>
              <IconMoreHoriz />
            </IconNavButton>
          </Tooltip>
        )}
      </Cont>

      <Menu.Root {...menu.rootProps}>
        {remaining.map((scheduleView) => (
          <MenuItem
            key={scheduleView.id}
            selected={pathname === routes.schedulePage(scheduleView.id)}
            onClick={() => {
              navigate(routes.schedulePage(scheduleView.id));
              menu.closeMenu();
            }}
          >
            <ListItemIcon>
              <IconFullStackedBarChart />
            </ListItemIcon>
            {scheduleView.name}
          </MenuItem>
        ))}
      </Menu.Root>
    </Container>
  );
}

function ProjectMenuComponent() {
  const projectId = useProjectId();

  const { data } = useHeaderProjectQuery({ projectId }, { enabled: projectId !== '' });

  const project = data?.project;

  if (projectId === '' || project == null) {
    return <Container />;
  }

  return <Component project={project} />;
}

export const ProjectMenu = memo(ProjectMenuComponent);
