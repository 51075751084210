export const Operations = {
  Query: {
    HeaderProject: 'HeaderProject',
    SchedulePreviewInfo: 'SchedulePreviewInfo',
    ScheduleViewPreviewInfo: 'ScheduleViewPreviewInfo',
    WorkSections: 'WorkSections',
    ScheduleSettings: 'ScheduleSettings',
    CustomFieldDefinitionsByProject: 'CustomFieldDefinitionsByProject',
    CustomFieldDefinitionsByScheduleView: 'CustomFieldDefinitionsByScheduleView',
    SystemAccounts: 'SystemAccounts',
    SystemAdmins: 'SystemAdmins',
    LandingPageAccounts: 'LandingPageAccounts',
    LandingPageSites: 'LandingPageSites',
    WorkPackageSnippet: 'WorkPackageSnippet',
    WorkPackageSnippets: 'WorkPackageSnippets',
    CurrentUser: 'CurrentUser',
    CustomCalendar: 'CustomCalendar',
    DynamicCalendar: 'DynamicCalendar',
    PaginateOperations: 'PaginateOperations',
    QuantitySpaces: 'QuantitySpaces',
    SchedulePage: 'SchedulePage',
    SchedulePageQuantities: 'SchedulePageQuantities',
    SchedulePageWorkPackages: 'SchedulePageWorkPackages',
    WorkPackage: 'WorkPackage',
    WorkPackageComments: 'WorkPackageComments',
    WorkPackagesOrCalendarOrSpacesHasChangedByOther: 'WorkPackagesOrCalendarOrSpacesHasChangedByOther',
    ResourceHistogramSuppliers: 'ResourceHistogramSuppliers',
    Notifications: 'Notifications',
    ArchivedProjects: 'ArchivedProjects',
    DeleteScheduleViewData: 'DeleteScheduleViewData',
    ProjectScheduleView: 'ProjectScheduleView',
    SitePage: 'SitePage',
    TemplateScheduleInfo: 'TemplateScheduleInfo',
    ScheduleViewDialog: 'ScheduleViewDialog',
    BasicSettings: 'BasicSettings',
    GetMeasurementUnits: 'GetMeasurementUnits',
    GetQuantities: 'GetQuantities',
    GetQuantityTasks: 'GetQuantityTasks',
    ProjectWorkPackages: 'ProjectWorkPackages',
    DeviceMeasurementHistory: 'DeviceMeasurementHistory',
    ProjectDevices: 'ProjectDevices',
    ScheduleViews: 'ScheduleViews',
    SituationalSnapshot: 'SituationalSnapshot',
    ProjectSiteTeamsAndUsers: 'ProjectSiteTeamsAndUsers',
    CompaniesAndAccountSuppliers: 'CompaniesAndAccountSuppliers',
    ProjectSuppliers: 'ProjectSuppliers',
    SupplierSiteUsers: 'SupplierSiteUsers',
    WorkTime: 'WorkTime',
    Accounts: 'Accounts',
    Namespace: 'Namespace',
    Namespaces: 'Namespaces',
    Project: 'Project',
    Projects: 'Projects',
    ProjectIds: 'ProjectIds',
    ProjectPhases: 'ProjectPhases',
    Site: 'Site',
    SiteName: 'SiteName',
    Sites: 'Sites',
    VirtualSpaceData: 'VirtualSpaceData',
    SystemAndAccountNamespacesTags: 'SystemAndAccountNamespacesTags',
    SystemNamespacesWorkSections: 'SystemNamespacesWorkSections',
    TagTreeData: 'TagTreeData',
    AccountUsers: 'AccountUsers',
    AccountApiUsers: 'AccountApiUsers',
    AccountUserEdit: 'AccountUserEdit',
    AccountSiteRoles: 'AccountSiteRoles',
    GetVirtualSpaceModelSite: 'GetVirtualSpaceModelSite',
    ProjectLocationBreakdowns: 'ProjectLocationBreakdowns',
    AccountCustomProjectCategories: 'AccountCustomProjectCategories',
    AccountSitesAndUsers: 'AccountSitesAndUsers',
    AccountTemplateSchedules: 'AccountTemplateSchedules',
    ScheduleInfo: 'ScheduleInfo',
    ScheduleViewWorkPackageInfo: 'ScheduleViewWorkPackageInfo',
    SiteSchedules: 'SiteSchedules',
    TemplateSchedule: 'TemplateSchedule',
    TemplateScheduleVersion: 'TemplateScheduleVersion',
    SiteTeams: 'SiteTeams',
    SiteUsers: 'SiteUsers',
    GetSpaceModelSite: 'GetSpaceModelSite',
    ProjectAndSpaces: 'ProjectAndSpaces',
    MembersAndCrewLeaders: 'MembersAndCrewLeaders',
    TeamDetails: 'TeamDetails',
    WorkSectionTags: 'WorkSectionTags',
    UserPermissions: 'UserPermissions'
  },
  Mutation: {
    CreateTimelineNote: 'CreateTimelineNote',
    CreateWorkTimeExceptions: 'CreateWorkTimeExceptions',
    RemoveManyWorkTimeExceptions: 'RemoveManyWorkTimeExceptions',
    RemoveTimelineNote: 'RemoveTimelineNote',
    UpdateSchedule: 'UpdateSchedule',
    UpdateTimelineNote: 'UpdateTimelineNote',
    UpdateWorkTimeException: 'UpdateWorkTimeException',
    UpdateWorkTimeExceptions: 'UpdateWorkTimeExceptions',
    UpdateWorkTimes: 'UpdateWorkTimes',
    RemoveCustomFieldDefinition: 'RemoveCustomFieldDefinition',
    UpsertCustomFieldDefinition: 'UpsertCustomFieldDefinition',
    UpsertWorkPackageCustomFields: 'UpsertWorkPackageCustomFields',
    RemoveWorkPackageCustomFieldValues: 'RemoveWorkPackageCustomFieldValues',
    addSystemAdmin: 'addSystemAdmin',
    createAccount: 'createAccount',
    removeSystemAdmin: 'removeSystemAdmin',
    updateAccount: 'updateAccount',
    UpsertScheduleViewSettings: 'UpsertScheduleViewSettings',
    CreateWorkPackageSnippet: 'CreateWorkPackageSnippet',
    IncrementSnippetAddCount: 'IncrementSnippetAddCount',
    RemoveWorkPackageSnippet: 'RemoveWorkPackageSnippet',
    ChangeWorkPackageHierarchy: 'ChangeWorkPackageHierarchy',
    CloneWorkPackageRecursive: 'CloneWorkPackageRecursive',
    CloneWorkPackages: 'CloneWorkPackages',
    ConvertMasterToSum: 'ConvertMasterToSum',
    CopyPasteWorkPackages: 'CopyPasteWorkPackages',
    CreateGanttQuantity: 'CreateGanttQuantity',
    CreateWorkPackageComment: 'CreateWorkPackageComment',
    CreateWorkPackageDeadlines: 'CreateWorkPackageDeadlines',
    CreateWorkPackageTasks: 'CreateWorkPackageTasks',
    CreateWorkPackages: 'CreateWorkPackages',
    DefineWorkPackageInstances: 'DefineWorkPackageInstances',
    MoveWorkPackages: 'MoveWorkPackages',
    RemoveWorkPackageDeadlines: 'RemoveWorkPackageDeadlines',
    RemoveWorkPackageTasks: 'RemoveWorkPackageTasks',
    RemoveWorkPackages: 'RemoveWorkPackages',
    UnstarWorkPackageDeadline: 'UnstarWorkPackageDeadline',
    SyncQuantityTasks: 'SyncQuantityTasks',
    UndoRemoveWorkPackages: 'UndoRemoveWorkPackages',
    StarWorkPackageDeadline: 'StarWorkPackageDeadline',
    UpdateScheduleQuantity: 'UpdateScheduleQuantity',
    UpdateWorkPackageDeadlines: 'UpdateWorkPackageDeadlines',
    UpdateWorkPackageEfficiency: 'UpdateWorkPackageEfficiency',
    UpdateWorkPackageInstanceEfficiency: 'UpdateWorkPackageInstanceEfficiency',
    UpdateWorkPackageProgress: 'UpdateWorkPackageProgress',
    UpdateWorkPackageTaskProgress: 'UpdateWorkPackageTaskProgress',
    UpdateWorkPackageTasks: 'UpdateWorkPackageTasks',
    UpdateWorkPackages: 'UpdateWorkPackages',
    UpsertLobNamePosition: 'UpsertLobNamePosition',
    UpsertWorkPackageOrder: 'UpsertWorkPackageOrder',
    CreateScheduleView: 'CreateScheduleView',
    ImportWorkPackagesFromTemplate: 'ImportWorkPackagesFromTemplate',
    RemoveScheduleView: 'RemoveScheduleView',
    UpdateScheduleView: 'UpdateScheduleView',
    UpdateProject: 'UpdateProject',
    CreateQuantity: 'CreateQuantity',
    CreateQuantityTasks: 'CreateQuantityTasks',
    DeleteQuantity: 'DeleteQuantity',
    DetachQuantitiesFromTasks: 'DetachQuantitiesFromTasks',
    UpdateQuantity: 'UpdateQuantity',
    UpdateQuantityOrders: 'UpdateQuantityOrders',
    UpdateQuantityTasks: 'UpdateQuantityTasks',
    UpsertProjectQuantitySpaces: 'UpsertProjectQuantitySpaces',
    MarkTaskAsCompleted: 'MarkTaskAsCompleted',
    CreateProjectSupplier: 'CreateProjectSupplier',
    RemoveProjectSupplier: 'RemoveProjectSupplier',
    UpdateProjectSupplier: 'UpdateProjectSupplier',
    UpsertProjectSupplierUsers: 'UpsertProjectSupplierUsers',
    RemoveCustomCalendar: 'RemoveCustomCalendar',
    CreateCustomCalendar: 'CreateCustomCalendar',
    RemoveProjectPublicHolidays: 'RemoveProjectPublicHolidays',
    UpsertProjectPublicHolidays: 'UpsertProjectPublicHolidays',
    CreateNamespace: 'CreateNamespace',
    CreateProject: 'CreateProject',
    CreateSite: 'CreateSite',
    lockNamespace: 'lockNamespace',
    RemoveNamespaceById: 'RemoveNamespaceById',
    UpdateAddressById: 'UpdateAddressById',
    UpdateCurrentUser: 'UpdateCurrentUser',
    UpdateNamespaceById: 'UpdateNamespaceById',
    UpdateProjectById: 'UpdateProjectById',
    UpdateSiteById: 'UpdateSiteById',
    UpsertSpaces: 'UpsertSpaces',
    UpsertTags: 'UpsertTags',
    UpsertUserPreferenceByKey: 'UpsertUserPreferenceByKey',
    UpsertVirtualSpaces: 'UpsertVirtualSpaces',
    UpsertWorkSections: 'UpsertWorkSections',
    CloneProject: 'CloneProject',
    RemoveProjectById: 'RemoveProjectById',
    UnarchiveProject: 'UnarchiveProject',
    AddAccountApiUser: 'AddAccountApiUser',
    RemoveAccountApiUser: 'RemoveAccountApiUser',
    editSiteUser: 'editSiteUser',
    RemoveAccountUser: 'RemoveAccountUser',
    removeSiteUser: 'removeSiteUser',
    CloneLocationBreakdown: 'CloneLocationBreakdown',
    removeProjectVirtualNamespace: 'removeProjectVirtualNamespace',
    CreateCustomProjectCategories: 'CreateCustomProjectCategories',
    CreateTemplateScheduleVersion: 'CreateTemplateScheduleVersion',
    CreateTemplateSchedule: 'CreateTemplateSchedule',
    RemoveCustomProjectCategories: 'RemoveCustomProjectCategories',
    RemoveTemplateSchedule: 'RemoveTemplateSchedule',
    UpdateCustomProjectCategories: 'UpdateCustomProjectCategories',
    UpdateTemplateSchedule: 'UpdateTemplateSchedule',
    UpdateTemplateScheduleVersion: 'UpdateTemplateScheduleVersion',
    removeSiteById: 'removeSiteById',
    SyncSpacesWithExternalSystem: 'SyncSpacesWithExternalSystem',
    createTeam: 'createTeam',
    removeTeam: 'removeTeam',
    updateTeam: 'updateTeam',
    AddAccountUser: 'AddAccountUser',
    AddSiteUser: 'AddSiteUser'
  },
  Fragment: {
    WorkTimeFields: 'WorkTimeFields',
    CustomFieldValue: 'CustomFieldValue',
    CustomFieldOption: 'CustomFieldOption',
    CustomFieldDefinitionFields: 'CustomFieldDefinitionFields',
    SystemAccountsFields: 'SystemAccountsFields',
    SnippetWorkPackageTaskFields: 'SnippetWorkPackageTaskFields',
    SnippetWorkPackageFields: 'SnippetWorkPackageFields',
    WorkPackageSnippetFields: 'WorkPackageSnippetFields',
    CurrentUserFields: 'CurrentUserFields',
    OperationFields: 'OperationFields',
    VirtualSpaces: 'VirtualSpaces',
    teamFields: 'teamFields',
    UserFields: 'UserFields',
    SiteFields: 'SiteFields',
    ScheduleWorkSectionFields: 'ScheduleWorkSectionFields',
    NamespaceFields: 'NamespaceFields',
    ProjectFields: 'ProjectFields',
    WorkTimeExceptionTimeFields: 'WorkTimeExceptionTimeFields',
    WorkTimeExceptionFields: 'WorkTimeExceptionFields',
    TimelineNoteFields: 'TimelineNoteFields',
    ScheduleFields: 'ScheduleFields',
    CalendarExceptionFields: 'CalendarExceptionFields',
    SchedulePageScheduleViewFields: 'SchedulePageScheduleViewFields',
    WorkPackageComment: 'WorkPackageComment',
    WorkPackageOrderFields: 'WorkPackageOrderFields',
    ScheduleQuantityFields: 'ScheduleQuantityFields',
    TaskInstanceData: 'TaskInstanceData',
    TaskMasterData: 'TaskMasterData',
    TaskCommonData: 'TaskCommonData',
    WorkPackageInstance: 'WorkPackageInstance',
    WorkPackageMaster: 'WorkPackageMaster',
    WorkPackageCommon: 'WorkPackageCommon',
    CalendarFields: 'CalendarFields',
    ScheduleViewWorkPackageData: 'ScheduleViewWorkPackageData',
    TaskFields: 'TaskFields',
    WorkPackageDeadlineFields: 'WorkPackageDeadlineFields',
    WorkPackageDetailFields: 'WorkPackageDetailFields',
    WorkPackageCommentFields: 'WorkPackageCommentFields',
    ProjectScheduleViewFields: 'ProjectScheduleViewFields',
    QuantitySpaceFields: 'QuantitySpaceFields',
    NamespaceLockFields: 'NamespaceLockFields',
    MdmProjectFields: 'MdmProjectFields',
    FilteredSpaceFields: 'FilteredSpaceFields',
    FilteredVirtualSpaceFields: 'FilteredVirtualSpaceFields',
    SystemNamespaceFields: 'SystemNamespaceFields',
    SystemWorkSectionFields: 'SystemWorkSectionFields',
    SystemTagFields: 'SystemTagFields',
    SystemSpaceFields: 'SystemSpaceFields',
    SpaceModelFields: 'SpaceModelFields',
    TagFields: 'TagFields',
    WorkSectionFields: 'WorkSectionFields',
    UserList: 'UserList',
    AccountUserEditTeamFields: 'AccountUserEditTeamFields',
    AccountUserEditFields: 'AccountUserEditFields',
    SitesRolesFields: 'SitesRolesFields',
    VirtualSpaceModelSiteFields: 'VirtualSpaceModelSiteFields',
    CrewLeadsAndMembersFields: 'CrewLeadsAndMembersFields',
    SiteTeamFields: 'SiteTeamFields',
    SiteFieldsInSpaceModelView: 'SiteFieldsInSpaceModelView',
    TeamCreateMutationFields: 'TeamCreateMutationFields',
    MembersCrewLeadersFields: 'MembersCrewLeadersFields',
    TeamUpdateMutationFields: 'TeamUpdateMutationFields',
    TeamDetailsFields: 'TeamDetailsFields',
    AccountUserFields: 'AccountUserFields',
    SiteUserMutationFields: 'SiteUserMutationFields',
    WorkSectionTagsFields: 'WorkSectionTagsFields'
  }
}