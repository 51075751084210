import { Theme } from '@mui/material/styles';
import { SimplePersistedState } from '../simpleState';

export const northstarColors = {
  purple: {
    50: '#F8F1FD',
    100: '#EFDFFB',
    200: '#E4CBF7',
    300: '#D4AEF2',
    400: '#C290ED',
    500: '#B57DE9',
    600: '#8A3CDD',
    700: '#7319D2',
    800: '#571A99',
    900: '#2A1145',
  },
  gray: {
    50: '#F2F4F5',
    100: '#E2E6EA',
    200: '#D0D5DC',
    300: '#B5BEC8',
    400: '#9AA6B5',
    500: '#8997A8',
    600: '#5A697D',
    700: '#4A5666',
    800: '#37414D',
    900: '#242B32',
    950: '#121416',
  },
  green: {
    50: '#E5F8F0',
    100: '#C3EFDC',
    200: '#99E4C5',
    300: '#57D29E',
    400: '#4BB88A',
    500: '#44A77E',
    600: '#287A57',
    700: '#275F47',
    800: '#1D4736',
    900: '#132F23',
  },
  yellow: {
    50: '#FEF3D1',
    100: '#FEE495',
    200: '#FED663',
    300: '#E3B740',
    400: '#C89F38',
    500: '#B79033',
    600: '#946F1B',
    700: '#69511D',
    800: '#503D16',
    900: '#35280F',
  },
  orange: {
    50: '#FFF7EF',
    100: '#FFEDDA',
    200: '#FFDFC0',
    300: '#FECC9A',
    400: '#FEB66E',
    500: '#F4A658',
    600: '#C77D34',
    700: '#8B5F32',
    800: '#6A4826',
    900: '#452F19',
  },
  red: {
    50: '#FFF0EF',
    100: '#FFDEDA',
    200: '#FFC8C0',
    300: '#FEA69A',
    400: '#FE7F6E',
    500: '#F46B58',
    600: '#BF4132',
    700: '#8B3C32',
    800: '#6A2E26',
    900: '#451E19',
  },
  magenta: {
    50: '#FCF0FC',
    100: '#F8DDF8',
    200: '#F4C7F4',
    300: '#EEA6EE',
    400: '#E785E7',
    500: '#E370E3',
    600: '#D52AD5',
    700: '#BD17BD',
    800: '#901190',
    900: '#5F0B5F',
  },
  blue: {
    50: '#F2F1FD',
    100: '#E1DFFB',
    200: '#CECBF7',
    300: '#B3AEF2',
    400: '#9690ED',
    500: '#6A60FB',
    600: '#473CDD',
    700: '#2519D2',
    800: '#221A99',
    900: '#141145',
  },
  prime: {
    white: '#ffffff',
    black: '#000000',
  },
};

const northstarLight = {
  textPrimary: northstarColors.prime.black,
  textSecondary: northstarColors.gray[600],
  textTertiary: northstarColors.gray[500],
  textDisabled: northstarColors.gray[400],
  textInvert: northstarColors.prime.white,
  colorAccent: northstarColors.purple[700],
  borderComponentDark: northstarColors.gray[300],
  borderComponentLight: northstarColors.gray[200],
  borderComponentAlpha: '',
  defaultIcon: northstarColors.gray[700],
  secondaryIcon: northstarColors.gray[500],
  inverseIcon: northstarColors.prime.white,
  surfacePrimary: northstarColors.prime.white,
  surfaceSecondary: northstarColors.gray[50],
  surfaceTertiary: northstarColors.gray[200],
  surfaceContrast: northstarColors.gray[950],
  primaryButton: northstarColors.gray[900],
  secondaryButton: northstarColors.gray[100],
  primaryForm: '',
  secondaryForm: '',
  errorLight: northstarColors.red[50],
  errorBorder: northstarColors.red[200],
  errorBright: northstarColors.red[500],
  errorContrast: northstarColors.red[600],
  errorDark: northstarColors.red[800],
};

const northstarDark: typeof northstarLight = {
  textPrimary: northstarColors.prime.white,
  textSecondary: northstarColors.gray[400],
  textTertiary: northstarColors.gray[600],
  textDisabled: northstarColors.gray[700],
  textInvert: northstarColors.prime.black,
  colorAccent: northstarColors.purple[400],
  borderComponentDark: northstarColors.gray[600],
  borderComponentLight: northstarColors.gray[700],
  borderComponentAlpha: '',
  defaultIcon: northstarColors.gray[200],
  secondaryIcon: northstarColors.gray[500],
  inverseIcon: northstarColors.prime.black,
  surfacePrimary: northstarColors.gray[900],
  surfaceSecondary: northstarColors.gray[800],
  surfaceTertiary: northstarColors.gray[700],
  surfaceContrast: northstarColors.gray[100],
  primaryButton: northstarColors.gray[50],
  secondaryButton: northstarColors.gray[700],
  primaryForm: '',
  secondaryForm: '',
  errorLight: northstarColors.red[900],
  errorBorder: northstarColors.red[700],
  errorBright: northstarColors.red[500],
  errorContrast: northstarColors.red[300],
  errorDark: northstarColors.red[100],
};

type ThemeType = 'light' | 'dark';
type ThemeKey = keyof typeof northstarLight;

export const northstarVars = Object.keys(northstarLight).reduce(
  (acc, key) => ({ ...acc, [key]: `var(${key})` }),
  {},
) as {
  [Key in ThemeKey]: `var(--${Key})`;
};

export const themeTypeState = new SimplePersistedState<ThemeType>('theme', 'light');

function assignNorthstarVars(type: ThemeType) {
  const root = document.documentElement;
  const chosenTheme = type === 'light' ? northstarLight : northstarDark;
  Object.entries(chosenTheme).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });
}

themeTypeState.subscribe((event) => {
  assignNorthstarVars(event.state);
});
