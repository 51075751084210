import { useMemo, useSyncExternalStore } from 'react';
import { useLatestFunction } from './hooks';

class SimpleStateEvent<Data> extends Event {
  state: Data;

  constructor(state: Data) {
    super('state-changed');
    this.state = state;
  }
}

export class SimpleState<Data> extends EventTarget {
  #state: Data;

  initialState: Data;

  constructor(initialState: Data) {
    super();
    this.#state = initialState;
    this.initialState = initialState;
  }

  set(state: Data | ((prevState: Data) => Data)) {
    const newState = typeof state === 'function' ? (state as (prevState: Data) => Data)(this.#state) : state;

    if (newState !== this.#state) {
      this.#state = newState;
      this.dispatchEvent(new SimpleStateEvent(this.#state));
    }

    return this.#state;
  }

  get() {
    return this.#state;
  }

  subscribe(listener: (event: SimpleStateEvent<Data>) => void) {
    const params = ['state-changed', listener as EventListener] as const;

    this.addEventListener(...params);
    return () => {
      this.removeEventListener(...params);
    };
  }
}

export class SimplePersistedState<Data> extends EventTarget {
  #state: Data;

  initialState: Data;

  #key: string;

  #handleError = (error: string) => {};

  constructor(key: string, initialState: Data, handleError?: (error: string) => void) {
    super();

    let state = initialState;
    this.#handleError = handleError ?? this.#handleError;

    try {
      const item = localStorage.getItem(key);
      if (item != null) {
        state = JSON.parse(item);
      }
    } catch (e) {
      const error = e instanceof Error ? e.message : JSON.stringify(e);
      this.#handleError(`Error getting localStorage item "${key}": ${error}`);
    }

    this.#state = state;
    this.initialState = state;
    this.#key = key;
  }

  set(state: Data | ((prevState: Data) => Data)) {
    const newState = typeof state === 'function' ? (state as (prevState: Data) => Data)(this.#state) : state;
    if (newState !== this.#state) {
      try {
        localStorage.setItem(this.#key, JSON.stringify(newState));
      } catch (e) {
        const error = e instanceof Error ? e.message : JSON.stringify(e);
        this.#handleError(`Error setting localStorage item "${this.#key}": ${error}`);
      }
      this.#state = newState;
      this.dispatchEvent(new SimpleStateEvent(this.#state));
    }

    return this.#state;
  }

  get() {
    return this.#state;
  }

  subscribe(listener: (event: SimpleStateEvent<Data>) => void) {
    const params = ['state-changed', listener as EventListener] as const;
    this.addEventListener(...params);
    return () => {
      this.removeEventListener(...params);
    };
  }
}

export function useSimpleState<Data>(state: SimpleState<Data> | SimplePersistedState<Data>) {
  const value = useSyncExternalStore(
    (listener) => state.subscribe(listener),
    () => {
      return state.get();
    },
  );

  const updater = useLatestFunction((params: Parameters<typeof state.set>[0]) => {
    return state.set(params);
  });

  return useMemo(() => [value, updater] as const, [updater, value]);
}

export function createSimpleStateHook<Data>(state: SimpleState<Data> | SimplePersistedState<Data>) {
  return () => useSimpleState(state);
}
