import { CircularProgress, styled } from '@mui/material';

const StyledSpinner = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

function LoadingSpinner({ className }: { className?: string }) {
  return (
    <StyledSpinner className={className}>
      <CircularProgress />
    </StyledSpinner>
  );
}

export default LoadingSpinner;
