export function nullableString(value: string | undefined | null) {
  return value != null && value?.length > 0 ? value : null;
}

export function padWith(num: number, size: number, char: string) {
  let numStr = num.toString();
  while (numStr.length < size) numStr = `${char}${num}`;
  return numStr;
}

export function isString(value: unknown): value is string {
  return value instanceof String || typeof value === 'string';
}

export function stringArrayToRecord(array: string[]) {
  const result: Record<string, string> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const item of array) {
    result[item] = item;
  }

  return result;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeOnlyFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
