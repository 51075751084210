import EditOffOutlined from '@material-symbols/svg-300/outlined/edit_off.svg?react';
import Add from '@material-symbols/svg-300/sharp/add.svg?react';
import Alarm from '@material-symbols/svg-300/sharp/alarm.svg?react';
import ArrowBack from '@material-symbols/svg-300/sharp/arrow_back.svg?react';
import ArrowDropdown from '@material-symbols/svg-300/sharp/arrow_drop_down-fill.svg?react';
import ArrowDropup from '@material-symbols/svg-300/sharp/arrow_drop_up-fill.svg?react';
import ArrowRight from '@material-symbols/svg-300/sharp/arrow_right.svg?react';
import BlockIcon from '@material-symbols/svg-300/sharp/block.svg?react';
import Cancel from '@material-symbols/svg-300/sharp/cancel.svg?react';
import Check from '@material-symbols/svg-300/sharp/check.svg?react';
import CheckCircleFilled from '@material-symbols/svg-300/sharp/check_circle-fill.svg?react';
import ChevronRight from '@material-symbols/svg-300/sharp/chevron_right.svg?react';
import CircleFilled from '@material-symbols/svg-300/sharp/circle-fill.svg?react';
import Close from '@material-symbols/svg-300/sharp/close.svg?react';
import Computer from '@material-symbols/svg-300/sharp/computer.svg?react';
import ContentCopy from '@material-symbols/svg-300/sharp/content_copy.svg?react';
import Delete from '@material-symbols/svg-300/sharp/delete.svg?react';
import DragHandle from '@material-symbols/svg-300/sharp/drag_handle.svg?react';
import DragIndicator from '@material-symbols/svg-300/sharp/drag_indicator.svg?react';
import Edit from '@material-symbols/svg-300/sharp/edit.svg?react';
import Error from '@material-symbols/svg-300/sharp/error.svg?react';
import ErrorFilled from '@material-symbols/svg-300/sharp/error-fill.svg?react';
import ExpandMore from '@material-symbols/svg-300/sharp/expand_more.svg?react';
import FitWidth from '@material-symbols/svg-300/sharp/fit_width.svg?react';
import FormatListBulleted from '@material-symbols/svg-300/sharp/format_list_bulleted.svg?react';
import FormatSize from '@material-symbols/svg-300/sharp/format_size.svg?react';
import FullStackedBarChart from '@material-symbols/svg-300/sharp/full_stacked_bar_chart.svg?react';
import Groups from '@material-symbols/svg-300/sharp/groups.svg?react';
import History from '@material-symbols/svg-300/sharp/history.svg?react';
import Home from '@material-symbols/svg-300/sharp/home.svg?react';
import Inventory from '@material-symbols/svg-300/sharp/inventory_2.svg?react';
import IosShare from '@material-symbols/svg-300/sharp/ios_share.svg?react';
import LocationOn from '@material-symbols/svg-300/sharp/location_on.svg?react';
import Lock from '@material-symbols/svg-300/sharp/lock.svg?react';
import LockOpen from '@material-symbols/svg-300/sharp/lock_open.svg?react';
import Mail from '@material-symbols/svg-300/sharp/mail.svg?react';
import MoreHoriz from '@material-symbols/svg-300/sharp/more_horiz.svg?react';
import OpenInFull from '@material-symbols/svg-300/sharp/open_in_full.svg?react';
import PendingFilled from '@material-symbols/svg-300/sharp/pending-fill.svg?react';
import Person from '@material-symbols/svg-300/sharp/person.svg?react';
import Print from '@material-symbols/svg-300/sharp/print.svg?react';
import RadioButtonChecked from '@material-symbols/svg-300/sharp/radio_button_checked.svg?react';
import RadioButtonUnchecked from '@material-symbols/svg-300/sharp/radio_button_unchecked.svg?react';
import RedoIcon from '@material-symbols/svg-300/sharp/redo.svg?react';
import RestartAlt from '@material-symbols/svg-300/sharp/restart_alt.svg?react';
import Send from '@material-symbols/svg-300/sharp/send.svg?react';
import Settings from '@material-symbols/svg-300/sharp/settings.svg?react';
import Star from '@material-symbols/svg-300/sharp/star.svg?react';
import StarFilled from '@material-symbols/svg-300/sharp/star-fill.svg?react';
import SwapVerticalCircle from '@material-symbols/svg-300/sharp/swap_vertical_circle.svg?react';
import Today from '@material-symbols/svg-300/sharp/today.svg?react';
import UndoIcon from '@material-symbols/svg-300/sharp/undo.svg?react';
import VisibilityOff from '@material-symbols/svg-300/sharp/visibility_off.svg?react';
import ZoomIn from '@material-symbols/svg-300/sharp/zoom_in.svg?react';
import ZoomOut from '@material-symbols/svg-300/sharp/zoom_out.svg?react';
import { createSvgIcon, Icon, styled } from '@mui/material';

import AccountTree from './custom/AccountTree.svg?react';
import ArrowLeft from './custom/ArrowLeft.svg?react';
import BlockerSmall from './custom/Blocker.svg?react';
import BlockerSmallHover from './custom/BlockerHover.svg?react';
import Checkbox from './custom/Checkbox.svg?react';
import CheckboxIndeterminate from './custom/CheckboxIndeterminate.svg?react';
import Clock from './custom/Clock.svg?react';
import CustomerSupport from './custom/CustomerSupport.svg?react';
import Deadline from './custom/Deadline.svg?react';
import DeleteChip from './custom/DeleteChip.svg?react';
import EditLine from './custom/EditLine.svg?react';
import Empty from './custom/Empty.svg?react';
import EmptyTaskStack from './custom/EmptyTaskStack.svg?react';
import Eye from './custom/Eye.svg?react';
import Filter from './custom/Filter.svg?react';
import FocusView from './custom/FocusView.svg?react';
import Folder from './custom/Folder.svg?react';
import Gantt from './custom/Gantt.svg?react';
import HalfEye from './custom/HalfEye.svg?react';
import Hierarchy from './custom/Hierarchy.svg?react';
import HierarchyContextOff from './custom/HierarchyContextOff.svg?react';
import HierarchyContextOn from './custom/HierarchyContextOn.svg?react';
import HighlightWorkPackage from './custom/Highlight.svg?react';
import Import from './custom/Import.svg?react';
import Info from './custom/Info.svg?react';
import LinkBroken from './custom/LinkBroken.svg?react';
import LoB from './custom/LoB.svg?react';
import MasterScheduleView from './custom/MasterScheduleView.svg?react';
import Mobile from './custom/Mobile.svg?react';
import MobilePhone from './custom/MobilePhone.svg?react';
import MoreVert from './custom/MoreVert.svg?react';
import Obstacle from './custom/Obstacle.svg?react';
import QuestionMark from './custom/QuestionMark.svg?react';
import Search from './custom/Search.svg?react';
import SnippetLibrary from './custom/SnippetLibrary.svg?react';
import Sort from './custom/Sort.svg?react';
import SynchronizedReadOnly from './custom/SynchronizedReadOnly.svg?react';
import Takt from './custom/Takt.svg?react';
import TaskDraggable from './custom/TaskDraggable.svg?react';
import TaskStack from './custom/TaskStack.svg?react';
import User from './custom/User.svg?react';
import UserThree from './custom/UserThree.svg?react';
import Verified from './custom/Verified.svg?react';
import VisibilityOn from './custom/VisibilityOn.svg?react';
import WorkQueue from './custom/WorkQueue.svg?react';
import Zoom from './custom/Zoom.svg?react';
import ZoomCalendar from './custom/ZoomCalendar.svg?react';
import ZoomDay from './custom/ZoomDay.svg?react';
import ZoomMonth from './custom/ZoomMonth.svg?react';
import ZoomQuartal from './custom/ZoomQuartal.svg?react';
import ZoomWeek from './custom/ZoomWeek.svg?react';

/* ################################# COMPONENTS ############################### */

export const IconFullStackedBarChartWithoutRotate = createSvgIcon(
  <FullStackedBarChart />,
  'IconFullStackedBarChartWithoutRotate',
);
export const IconFullStackedBarChart = styled(IconFullStackedBarChartWithoutRotate)`
  transform: rotate(90deg);
`;

export const IconFormatListBulleted = createSvgIcon(<FormatListBulleted />, 'IconFormatListBulleted');
export const IconZoomIn = createSvgIcon(<ZoomIn />, 'IconZoomIn');
export const IconZoomOut = createSvgIcon(<ZoomOut />, 'IconZoomOut');
export const IconFilter = createSvgIcon(<Filter />, 'IconFilter');
export const IconRestartAlt = createSvgIcon(<RestartAlt />, 'IconRestartAlt');
export const IconDelete = createSvgIcon(<Delete />, 'IconDelete');
export const IconInventory = createSvgIcon(<Inventory />, 'IconInventory');
export const IconHome = createSvgIcon(<Home />, 'IconHome');
export const IconDragHandle = createSvgIcon(<DragHandle />, 'IconDragHandle');
export const IconDragIndicator = createSvgIcon(<DragIndicator />, 'IconDragIndicator');
export const IconIosShare = createSvgIcon(<IosShare />, 'IconIosShare');
export const IconCancel = createSvgIcon(<Cancel />, 'IconCancel');
export const IconSettings = createSvgIcon(<Settings />, 'IconSettings');
export const IconChevronRight = createSvgIcon(<ChevronRight />, 'IconChevronRight');
export const IconMoreHoriz = createSvgIcon(<MoreHoriz />, 'IconMoreHoriz');
export const IconAdd = createSvgIcon(<Add />, 'IconAdd');
export const IconClose = createSvgIcon(<Close />, 'IconClose');
export const IconSwapVerticalCircle = createSvgIcon(<SwapVerticalCircle />, 'IconSwapVerticalCircle');
export const IconEdit = createSvgIcon(<Edit />, 'IconEdit');
export const IconEditLine = createSvgIcon(<EditLine />, 'IconEditLine');
export const IconSend = createSvgIcon(<Send />, 'IconSend');
export const IconArrowBack = createSvgIcon(<ArrowBack />, 'IconArrowBack');
export const IconMail = createSvgIcon(<Mail />, 'IconMail');
export const IconStar = createSvgIcon(<Star />, 'IconStar');
export const IconZoomDay = createSvgIcon(<ZoomDay />, 'IconDay');
export const IconZoomWeek = createSvgIcon(<ZoomWeek />, 'IconWeek');
export const IconZoomMonth = createSvgIcon(<ZoomMonth />, 'IconMonth');
export const IconZoomQuartal = createSvgIcon(<ZoomQuartal />, 'IconQuartal');
export const IconZoomCalendar = createSvgIcon(<ZoomCalendar />, 'IconZoomCalendar');
export const IconZoom = createSvgIcon(<Zoom />, 'IconZoom');
export const IconDeadline = createSvgIcon(<Deadline />, 'IconDeadline');
export const IconTakt = createSvgIcon(<Takt />, 'IconTakt');
export const IconMobile = createSvgIcon(<Mobile />, 'IconMobile');
export const IconMobilePhone = createSvgIcon(<MobilePhone />, 'IconMobilePhone');
export const IconGantt = createSvgIcon(<Gantt />, 'IconGantt');
export const IconFormatSize = createSvgIcon(<FormatSize />, 'IconFormatSize');
export const IconArrowDropdown = createSvgIcon(<ArrowDropdown />, 'IconArrowDropdown');
export const IconArrowDropup = createSvgIcon(<ArrowDropup />, 'IconArrowDropup');
export const IconStarFilled = createSvgIcon(<StarFilled />, 'IconStarFilled');
export const IconEditOffOutlined = createSvgIcon(<EditOffOutlined />, 'EditOffOutlined');
export const IconErrorFilled = createSvgIcon(<ErrorFilled />, 'IconErrorFilled');
export const IconError = createSvgIcon(<Error />, 'IconError');
export const IconLocationOn = createSvgIcon(<LocationOn />, 'IconLocationOn');
export const IconAlarm = createSvgIcon(<Alarm />, 'IconAlarm');
export const IconGroups = createSvgIcon(<Groups />, 'IconGroups');
export const IconPerson = createSvgIcon(<Person />, 'IconPerson');
export const IconCircleFilled = createSvgIcon(<CircleFilled />, 'IconCircleFilled');
export const IconCheckCircleFilled = createSvgIcon(<CheckCircleFilled />, 'IconCheckCircleFilled');
export const IconPendingFilled = createSvgIcon(<PendingFilled />, 'IconPendingFilled');
export const IconMoreVert = createSvgIcon(<MoreVert />, 'IconMoreVert');
export const IconPrint = createSvgIcon(<Print />, 'IconPrint');
export const IconFitWidth = createSvgIcon(<FitWidth />, 'IconFitWidth');
export const IconToday = createSvgIcon(<Today />, 'IconToday');
export const IconExpandMore = createSvgIcon(<ExpandMore />, 'IconExpandMore');
export const IconPlaceholder = createSvgIcon(<Icon />, 'IconPlaceholder');
export const IconMasterScheduleView = createSvgIcon(<MasterScheduleView />, 'MasterScheduleView');
export const IconBlock = createSvgIcon(<BlockIcon />, 'IconBlock');
export const IconRedo = createSvgIcon(<RedoIcon />, 'IconRedo');
export const IconUndo = createSvgIcon(<UndoIcon />, 'IconUndo');
export const IconImport = createSvgIcon(<Import />, 'IconImport');
export const IconArrowLeft = createSvgIcon(<ArrowLeft />, 'IconArrowLeft');
export const IconContentCopy = createSvgIcon(<ContentCopy />, 'IconContentCopy');
export const IconHighlightWorkPackage = createSvgIcon(<HighlightWorkPackage />, 'IconHighlightWorkPackage');
export const IconDeleteChip = createSvgIcon(<DeleteChip />, 'IconDeleteChip');
export const IconVisibilityOn = createSvgIcon(<VisibilityOn />, 'IconVisibilityOn');
export const IconFocusView = createSvgIcon(<FocusView />, 'IconFocusView');
export const IconAccountTree = createSvgIcon(<AccountTree />, 'IconAccountTree');
export const IconHierarchy = createSvgIcon(<Hierarchy />, 'IconHierarchy');
export const IconHierarchyContextOn = createSvgIcon(<HierarchyContextOn />, 'IconHierarchyContextOn');
export const IconHierarchyContextOff = createSvgIcon(<HierarchyContextOff />, 'IconHierarchyContextOff');
export const IconCheck = createSvgIcon(<Check />, 'IconCheck');
export const IconEye = createSvgIcon(<Eye />, 'IconEye');
export const IconHalfEye = createSvgIcon(<HalfEye />, 'IconHalfEye');
export const IconHistory = createSvgIcon(<History />, 'IconHistory');
export const IconComputer = createSvgIcon(<Computer />, 'IconComputer');
export const IconEmpty = createSvgIcon(<Empty />, 'IconEmpty');
export const IconSort = createSvgIcon(<Sort />, 'IconSort');
export const IconSnippetLibrary = createSvgIcon(<SnippetLibrary />, 'IconSnippetLibrary');
export const IconBlockerSmall = createSvgIcon(<BlockerSmall />, 'IconBlockerSmall');
export const IconBlockerHoverSmall = createSvgIcon(<BlockerSmallHover />, 'IconBlockerHoverSmall');
export const IconCheckbox = createSvgIcon(<Checkbox />, 'IconCheckbox');
export const IconCheckboxIndeterminate = createSvgIcon(<CheckboxIndeterminate />, 'IconCheckboxIndeterminate');
export const IconVisibilityOff = createSvgIcon(<VisibilityOff />, 'IconVisibilityOff');
export const IconRadioButtonChecked = createSvgIcon(<RadioButtonChecked />, 'IconRadioButtonChecked');
export const IconRadioButtonUnchecked = createSvgIcon(<RadioButtonUnchecked />, 'IconRadioButtonUnchecked');
export const IconWorkQueue = createSvgIcon(<WorkQueue />, 'IconWorkQueue');
export const IconSearch = createSvgIcon(<Search />, 'IconSearch');
export const IconArrowRight = createSvgIcon(<ArrowRight />, 'IconArrowRight');
export const IconLoB = createSvgIcon(<LoB />, 'IconLoB');
export const IconLockOpen = createSvgIcon(<LockOpen />, 'IconLockOpen');
export const IconLock = createSvgIcon(<Lock />, 'IconLock');
export const IconSynchronizedReadOnly = createSvgIcon(<SynchronizedReadOnly />, 'IconSynchronizedReadOnly');
export const IconOpenInFull = createSvgIcon(<OpenInFull />, 'IconOpenFull');
export const IconClock = createSvgIcon(<Clock />, 'IconClock');
export const IconObstacle = createSvgIcon(<Obstacle />, 'IconObstacle');
export const IconInfo = createSvgIcon(<Info />, 'IconInfo');
export const IconQuestionMark = createSvgIcon(<QuestionMark />, 'IconQuestionMark');
export const IconVerified = createSvgIcon(<Verified />, 'IconVerified');
export const IconFolder = createSvgIcon(<Folder />, 'IconFolder');
export const IconTaskStack = createSvgIcon(<TaskStack />, 'IconTaskStack');
export const IconUser = createSvgIcon(<User />, 'IconUser');
export const IconUserThree = createSvgIcon(<UserThree />, 'IconUserThree');
export const IconEmptyTaskStack = createSvgIcon(<EmptyTaskStack />, 'EmptyTaskStack');
export const IconTaskDraggable = createSvgIcon(<TaskDraggable />, 'IconTaskDraggable');
export const IconLinkBroken = createSvgIcon(<LinkBroken />, 'IconLinkBroken');
export const IconCustomerSupport = createSvgIcon(<CustomerSupport />, 'IconCustomerSupport');
