import { Button } from '@mui/material';
import { colors } from '../../config';

import { type PrimaryButtonProps } from './PrimaryButton';

function SecondaryButton({
  children,
  onClick,
  disabled,
  minWidth,
  fontColor,
  value,
  marginBottom,
  className,
  backgroundColor,
}: PrimaryButtonProps) {
  return (
    <Button
      className={className}
      disabled={disabled}
      variant="outlined"
      onClick={onClick}
      value={value}
      sx={{
        backgroundColor: backgroundColor ?? 'transparent',
        marginBottom: marginBottom ?? 0,
        color: fontColor ?? colors['M3/sys/light/primary'],
        borderColor: fontColor ?? colors['M3/sys/light/primary'],

        textTransform: 'none',
        borderRadius: 28,
        minWidth: minWidth ?? 120,
        padding: '0.5rem 1rem',

        marginRight: 1,

        ':hover': {
          backgroundColor: backgroundColor ?? 'transparent',
          color: fontColor ?? colors['M3/sys/light/primary'],
          borderColor: fontColor ?? colors['M3/sys/light/primary'],

          opacity: 0.8,
        },
      }}
    >
      {children}
    </Button>
  );
}

export { SecondaryButton };
