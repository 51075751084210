import { useCallback } from 'react';
import { subject as createSubject } from '@casl/ability';

import { usePermissionContext } from './permissions.context';
import { Action, type SubjectMap } from './permissions.types';

export function useBuildBermissions() {
  const { isReady, ability } = usePermissionContext();
  return useCallback(
    (subject: SubjectMap) =>
      isReady
        ? {
            create: ability.can(Action.Create, subject),
            read: ability.can(Action.Read, subject),
            update: ability.can(Action.Update, subject),
            delete: ability.can(Action.Delete, subject),
          }
        : { create: false, read: false, update: false, delete: false },
    [ability, isReady],
  );
}

export function useGetPermissionsToAccount() {
  const build = useBuildBermissions();
  return useCallback((id: string) => build(createSubject('Account', { id })), [build]);
}

export function usePermissionsToAccount(id: string) {
  return useGetPermissionsToAccount()(id);
}

export function useGetPermissionsToSite() {
  const build = useBuildBermissions();
  return useCallback((id: string) => build(createSubject('Site', { id })), [build]);
}

export function usePermissionsToSite(id: string) {
  return useGetPermissionsToSite()(id);
}

export function useGetPermissionsToProject() {
  const build = useBuildBermissions();
  return useCallback((id: string) => build(createSubject('Project', { id })), [build]);
}

export function usePermissionsToProject(id: string) {
  return useGetPermissionsToProject()(id);
}

export function useGetPermissionsToTask() {
  const build = useBuildBermissions();
  return useCallback((id: string, teamId: string) => build(createSubject('Task', { id, team_id: teamId })), [build]);
}

export function usePermissionsToTask(id: string, teamId: string) {
  return useGetPermissionsToTask()(id, teamId);
}

export function useIsSystemAdmin() {
  const { ability } = usePermissionContext();
  return ability.can(Action.Manage, 'all');
}

export function usePermissionsLoaded() {
  const { isReady } = usePermissionContext();
  return isReady;
}
