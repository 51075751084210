import { isRouteErrorResponse } from 'react-router';
import { type NotifiableError } from '@bugsnag/js';

export function processError(error: unknown): NotifiableError {
  if (error instanceof Error) {
    return error;
  }

  if (isRouteErrorResponse(error)) {
    return { name: `Fetch error: ${error.status}`, message: error.statusText };
  }

  if (typeof error === 'string' || typeof error === 'number') {
    return String(error);
  }

  return JSON.stringify(error);
}
