import { type FirebaseOptions } from 'firebase/app';
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth';

export const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_APIKEY,
  // TODO: once we launch 1.0, change this to our custom domain (docs: https://firebase.google.com/docs/auth/web/microsoft-oauth#customizing-the-redirect-domain-for-microsoft-sign-in)
  authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECTID,
  appId: import.meta.env.VITE_FIREBASE_APPID,
};

const microsoftAuthProvider = new OAuthProvider('microsoft.com');
microsoftAuthProvider.setCustomParameters({
  prompt: 'select_account',
});

const googleAuthProvider = new GoogleAuthProvider();

export { googleAuthProvider, microsoftAuthProvider };
