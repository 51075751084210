import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { json, redirect, useActionData } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { FirebaseError } from '@firebase/util';
import {
  AuthErrorCodes,
  type AuthProvider,
  getAuth,
  isSignInWithEmailLink,
  linkWithPopup,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

import { routerRoutes } from '~/config/routes';
import { processError } from '~/utils/error';
import toast from '~/utils/toast';
import { isNonEmptyString } from '~/utils/typeGuards';

import { googleAuthProvider, microsoftAuthProvider } from '../config/firebase';

import { authPaths } from './auth.paths';

const ORIGINAL_URL_KEY = 'originalUrl';
const EMAIL_KEY = 'emailForSignIn';

export const setOriginalUrl = (url: string) => {
  sessionStorage.setItem(ORIGINAL_URL_KEY, url);
};

export const clearOriginalUrl = () => {
  sessionStorage.removeItem(ORIGINAL_URL_KEY);
};

export const getAndRemoveOriginalUrl = (fallback = '/') => {
  const originalUrl = sessionStorage.getItem(ORIGINAL_URL_KEY);
  clearOriginalUrl();
  return originalUrl ?? fallback;
};

async function signInWithProvider(provider: AuthProvider) {
  const auth = getAuth();
  await signInWithPopup(auth, provider);
  window.location.href = getAndRemoveOriginalUrl();
  return null;
}

export async function signInWithMicrosoft() {
  return signInWithProvider(microsoftAuthProvider);
}

export async function signInWithGoogle() {
  return signInWithProvider(googleAuthProvider);
}

export async function sendEmailSignInLink(email: string) {
  const auth = getAuth();
  window.localStorage.setItem(EMAIL_KEY, email);

  await sendSignInLinkToEmail(auth, email, {
    url: `${window.location.origin}${authPaths.finishSignIn}`,
    handleCodeInApp: true,
    iOS: {
      bundleId: import.meta.env.VITE_IOS_BUNDLE_ID,
    },
    android: {
      packageName: import.meta.env.VITE_ANDROID_PACKAGE_NAME,
      installApp: true,
      minimumVersion: '12',
    },
  });

  return redirect(authPaths.emailSent);
}

export async function signInWithEmail(authUrl: string) {
  const auth = getAuth();
  const email = localStorage.getItem(EMAIL_KEY);

  if (!isSignInWithEmailLink(auth, authUrl) || !isNonEmptyString(email)) {
    return redirect('/');
  }

  await signInWithEmailLink(auth, email, authUrl);

  window.location.href = getAndRemoveOriginalUrl();
  return null;
}

export function authError(error: unknown) {
  const code = error instanceof FirebaseError ? error.code : 'unspecific';
  return json({ code }, { status: 400 });
}

export function isAuthError(error: unknown): error is { code: string } {
  return typeof error === 'object' && Object.prototype.hasOwnProperty.call(error, 'code');
}

export function useAuthErrorMessage(error: unknown): { message: string; existsWithDifferentCredentialsError: boolean } {
  const { t } = useTranslation();

  // eslint-disable-next-line no-extra-boolean-cast
  if (!Boolean(error)) {
    return { message: '', existsWithDifferentCredentialsError: false };
  }

  if (!isAuthError(error)) {
    return { message: t('error.unspecific'), existsWithDifferentCredentialsError: false };
  }

  return {
    message: t(`error.${error.code}`, 'error.auth/unspecific'),
    existsWithDifferentCredentialsError: error.code === AuthErrorCodes.NEED_CONFIRMATION,
  };
}

export function useAsyncError() {
  const actionData = useActionData();
  const [error, setError] = useState<unknown>(null);
  const errorMessage = useAuthErrorMessage(error);

  useEffect(() => {
    if (actionData != null) {
      setError(actionData);
    }
  }, [actionData]);

  return {
    errorMessage,
    isError: errorMessage.message.length > 0,
    clearError: () => {
      setError(null);
    },
  };
}

export async function logoutLoader() {
  const auth = getAuth();
  await signOut(auth);
  window.location.pathname = routerRoutes.logoutPage;
  return null;
}

export async function linkAccountWithMicrosoft(toastMessage: { success: string; error: string }) {
  const auth = getAuth();
  if (auth.currentUser == null) {
    return;
  }

  try {
    await linkWithPopup(auth.currentUser, microsoftAuthProvider);
  } catch (error) {
    Bugsnag.notify(processError(error));
    toast(toastMessage.error);
    return;
  }
  toast(toastMessage.success);
}
