/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { type QueryCache } from '@tanstack/react-query';

import {
  type CopyPasteWorkPackageInput,
  type SchedulePageWorkPackagesQuery,
  useSchedulePageWorkPackagesQuery,
} from '~/@generated/graphql';
import { getWorkPackageId } from '~/utils/id';
import { copyKeys } from '~/utils/object';

import { type ApiWorkPackage, type ResizeInput } from '../data/schedule-data.types';
import { mapDataToMoveResizeInput } from '../helpers/format';

const NIL_UUID = '00000000-0000-0000-0000-000000000000';

export function getQueryData(queryCache: QueryCache, scheduleViewId: string) {
  const queryKey = useSchedulePageWorkPackagesQuery.getKey({ scheduleViewId });
  const currentData = queryCache.get<SchedulePageWorkPackagesQuery>(JSON.stringify(queryKey));

  if (currentData == null) {
    return null;
  }

  type WorkPackage = Pick<ApiWorkPackage, 'id' | 'virtual_space_id' | 'planned_start' | 'planned_end' | 'parent_id'> & {
    startSeconds: number;
    endSeconds: number;
  };

  const instances: Record<string, WorkPackage> = {};
  const masters: Record<string, WorkPackage> = {};

  for (const data of currentData.state.data?.scheduleView.work_package_data_nested.work_packages ?? []) {
    const { id, name } = data;
    const common = { id, name };

    for (const instance of data.instances ?? []) {
      if (instance.virtual_space_id === NIL_UUID) {
        continue;
      }

      const combinedId = getWorkPackageId({ id: data.id, virtual_space_id: instance.virtual_space_id });

      instances[combinedId] = {
        ...common,
        virtual_space_id: instance.virtual_space_id,
        planned_start: instance.planned_start,
        planned_end: instance.planned_end,
        parent_id: id,
        startSeconds: instance.s_low,
        endSeconds: instance.s_high,
      };
    }

    const [master] = data.master ?? [];

    if (master != null) {
      masters[id] = {
        ...common,
        virtual_space_id: null,
        planned_start: master.planned_start,
        planned_end: master.planned_end,
        parent_id: data.parent_id,
        startSeconds: master.s_low,
        endSeconds: master.s_high,
      };
    }
  }

  return { instances, masters, workPackages: { ...instances, ...masters } };
}

export function generatePreviousStateMoveOrResizeVariables(
  scheduleViewId: string | null,
  queryCache: QueryCache,
  inputVariables: { input: ResizeInput[] },
) {
  if (scheduleViewId == null) {
    return null;
  }
  const currentData = getQueryData(queryCache, scheduleViewId);

  if (currentData == null) {
    return null;
  }

  const { instances, masters } = currentData;

  const updatedInstanceIds = new Set<string>();
  const updatedMasterIds = new Set<string>();

  inputVariables.input.forEach((input) => {
    if (input.virtual_space_id == null) {
      updatedMasterIds.add(getWorkPackageId(input));
      return;
    }
    updatedInstanceIds.add(getWorkPackageId(input));
  });

  const previousVariables: ResizeInput[] = [];

  Object.values(instances).forEach((wp) => {
    const id = getWorkPackageId(wp);
    if (updatedInstanceIds.has(id)) {
      const input = inputVariables.input.find((elem) => getWorkPackageId(elem) === id);
      if (input != null) {
        const item: ResizeInput = copyKeys(input, wp);
        item.startSeconds = wp.startSeconds;
        item.endSeconds = wp.endSeconds;
        previousVariables.push(item);
      }
    }
  });

  Object.values(masters).forEach((wp) => {
    const id = getWorkPackageId(wp);
    if (updatedMasterIds.has(id)) {
      const input = inputVariables.input.find((elem) => getWorkPackageId(elem) === id);
      if (input != null) {
        const item: ResizeInput = copyKeys(input, wp);
        item.startSeconds = wp.startSeconds;
        item.endSeconds = wp.endSeconds;
        previousVariables.push(item);
      }
    }
  });

  const orphanInput = inputVariables.input.filter(
    (inputItem) => !previousVariables.some((prevItem) => getWorkPackageId(prevItem) === getWorkPackageId(inputItem)),
  );

  // moving/resizing upper level won't exist in the previous state, so we extract the planned_start and planned_end from the master by id
  orphanInput.forEach((orphan) => {
    const master = Object.values(masters).find((item) => item.id === orphan.id);
    if (master == null) {
      return;
    }
    previousVariables.push({
      ...orphan,
      planned_start: master.planned_start,
      planned_end: master.planned_end,
      startSeconds: master.startSeconds,
      endSeconds: master.endSeconds,
    });
  });

  return { input: previousVariables };
}

export function generatePreviousCopyPasteVariables(
  scheduleViewId: string | null,
  queryCache: QueryCache,
  inputVariables: { input: CopyPasteWorkPackageInput[] },
) {
  if (scheduleViewId == null || inputVariables == null) {
    return null;
  }
  const currentData = getQueryData(queryCache, scheduleViewId);

  if (currentData == null) {
    return null;
  }

  const updatedOrCreatedInstanceIds = new Set<string>();

  let removableWps = inputVariables.input.map((item) => ({
    id: item.id,
    virtual_space_id: item.to_virtual_space_id,
  }));

  inputVariables.input.forEach((item) => {
    updatedOrCreatedInstanceIds.add(getWorkPackageId({ id: item.id, virtual_space_id: item.to_virtual_space_id }));
  });

  const previousVariables: ResizeInput[] = [];

  const { instances } = currentData;
  Object.values(instances).forEach((wp) => {
    if (updatedOrCreatedInstanceIds.has(getWorkPackageId(wp))) {
      const data = mapDataToMoveResizeInput(wp);
      previousVariables.push({
        ...data,
        parent_id: wp.parent_id ?? null,
        startSeconds: wp.startSeconds,
        endSeconds: wp.endSeconds,
      });
      removableWps = removableWps.filter((removableWp) => getWorkPackageId(removableWp) !== getWorkPackageId(wp));
    }
  });

  return {
    update: previousVariables,
    remove: removableWps,
  };
}
