import { checkVAT, countries } from 'jsvat';

export const isValidE164PhoneNumber = (phone: string) => /^\+?[1-9]\d{1,14}$/.test(phone);

export enum SupportedCodes {
  DUNS_NUMBER = 'DUNS_NUMBER',
  VAT_NUMBER = 'VAT_NUMBER',
  BUSINESS_ID = 'BUSINESS_ID',
  EIN_NUMBER = 'EIN_NUMBER',
  BRC_NUMBER = 'BRC_NUMBER',
  USCC_NUMBER = 'USCC_NUMBER',
}

export const isCodeFormatValid = ({
  codeName,
  value,
  countryCode,
}: {
  codeName: SupportedCodes;
  value: string;
  countryCode: string;
}): boolean => {
  switch (codeName) {
    case SupportedCodes.DUNS_NUMBER:
      return /^\d{9}$/.test(value);
    case SupportedCodes.BUSINESS_ID: {
      // Only for Finnish business ids
      const country = countries.find((c) => c.codes.includes(countryCode));
      if (country != null) {
        const vatCheckResult = checkVAT(`FI${value}`, [country]);
        if (!vatCheckResult.isValid) {
          return false;
        }
      }
      return /^\d{7}-\d{1}$/.test(value);
    }

    case SupportedCodes.EIN_NUMBER:
      return /^\d{2}-\d{7}$/.test(value);

    case SupportedCodes.BRC_NUMBER:
      return /^[A-Za-z]{1,2}\d{6}(\d|A)$/.test(value);

    case SupportedCodes.USCC_NUMBER:
      return /^\w{18}$/.test(value);

    case SupportedCodes.VAT_NUMBER: {
      const country = countries.find((c) => c.codes.includes(countryCode));
      if (country != null) {
        const vatCheckResult = checkVAT(value, [country]);
        return vatCheckResult.isValid;
      }
      return false;
    }
    default:
      return false;
  }
};
