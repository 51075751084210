import { createContext, useContext, useEffect, useMemo } from 'react';
import { AbilityBuilder, createMongoAbility } from '@casl/ability';

import { useUserPermissionsQuery } from '~/@generated/graphql';
import { mutationCache } from '~/config/queries';
import { invariant } from '~/utils/invariant';

import { type AppAbility } from './permissions.types';

const INVALIDATE_AFTER_MUTATION = ['CreateSite', 'CreateProject'];

function usePermissionContextValue() {
  const { data, refetch } = useUserPermissionsQuery({}, { staleTime: 0 });

  useEffect(() => {
    const unsubscribe = mutationCache.subscribe((event) => {
      if (
        event.type === 'updated' &&
        event.action.type === 'success' &&
        INVALIDATE_AFTER_MUTATION.includes(event.mutation.options.mutationKey?.[0] as string)
      ) {
        void refetch();
      }
    });
    return unsubscribe;
  }, [refetch]);

  return useMemo(() => {
    const rules = data?.current_user.permissions ?? [];
    const builder = new AbilityBuilder<AppAbility>(createMongoAbility);
    builder.rules = rules as unknown as AppAbility['rules'];
    return { isReady: data != null, ability: builder.build() };
  }, [data]);
}

type ContextType = ReturnType<typeof usePermissionContextValue>;

const Context = createContext<ContextType | null>(null);

export function PermissionProvider(props: React.PropsWithChildren) {
  const value = usePermissionContextValue();
  return <Context.Provider {...props} value={value} />;
}

export function usePermissionContext() {
  const ctx = useContext(Context);
  invariant(ctx, 'useAbilityContext must be used within a PermissionProvider');
  return ctx;
}
