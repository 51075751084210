import { invariant } from './invariant';

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow ?? false) {
    return value;
  }

  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export function assertExists<T>(value: T | null | undefined, message?: string): T {
  invariant(value != null, message);
  return value;
}
