import { createTheme } from '@mui/material/styles';
import { type SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { northstarColors } from './northstar';

interface VirtualSpaceTheme {
  background?: SimplePaletteColorOptions;
  text?: SimplePaletteColorOptions;
}

export const colors = {
  'M3/sys/light/primary': '#6750A4',
  'M3/sys/light/on-primary': '#FFFFFF',
  'M3/white': '#FFFFFF',
  'M3/sys/light/secondary-container': '#F6EDFF',
  'M3/sys/light/on-secondary-container': '#1D192B',
  'M3/sys/light/on-primary-container': '#21005D',
  'M3/sys/light/outline': '#79747E',
  'M3/sys/light/error': '#B3261E',
  'M3/sys/dark/secondary': '#CCC2DC',
  'M3/sys/light/on-surface-variant': '#49454F',
  'M3/sys/light/outline-variant': '#CAC4D0',
  'M3/sys/light/surface-variant': '#E7E0EC',
  'M3/sys/dark/error-container': '#8C1D18',
  'M3/ref/primary/primary60': '#9A82DB',
  'M3/ref/primary/primary70': '#B69DF8',
  'M3/ref/primary/primary90': '#EADDFF',
  'M3/ref/primary/primary95': '#F6EDFF',
  'M3/ref/primary/primary99': '#FFFBFE',
  'M3/ref/neutral/neutral90': '#E6E1E5',
  'M3/sys/light/inverse-on-surface': '#F4EFF4',
  'M3/surfaces/light/surface': '#F7F2F9',
  'M3/ref/neutral/neutral10': '#1C1B1F',
  'M3/ref/neutral/neutral30': '#484649',
  'M3/ref/neutral/neutral50': '#787579',
  'M3/ref/neutral/neutral60': '#939094',
  'M3/ref/neutral/neutral70': '#AEAAAE',
  error: {
    light: '#B3261E',
    dark: '#8C1D18',
  },
} as const;

const statusColors = {
  planned: '#F8F8F8',
  readyToStart: '#EBF3FF',
  inProgress: '#5F9EF9',
  inProgressDelayed: '#F4B76B',
  delayed: '#F4B76B',
  blocked: '#F88080',
  completed: '#5AB24C',
};

const schedule = {
  statusColors,
  teamDefaultColors: {
    normal: '#d4d4d4',
    sum: '#484649',
  },
  deadlineColors: {
    light: {
      EXTERNAL: '#EB1400',
      INTERNAL: '#EBB400',
    },
    dark: {
      EXTERNAL: '#850B00',
      INTERNAL: '#856600',
    },
  },
} as const;

export const threeSixty = {
  production: {
    '-1': '#DB4325',
    '0': '#E6E1BC',
    '1': '#006164',
    noData: '#C7C2C2',
  },
  process: {
    '-1': '#ffffff',
    '0': '#57C4AD',
    '1': '#006164',
    noData: '#C7C2C2',
  },
  background: '#EBEBEB',
  highlightBackground: '#C0D1D2',
  maturityArea: '#C0D1D2',
  border: '#A7A7A7',
  chart: ['#381E72', '#394F89', '#326C9A', '#2988A4', '#29A0A8', '#3DB7A7', '#5FCAA1', '#89DA99', '#B5E790', '#E3F18A'],
};

// NOTE: this is defined in devices.types aswell
// NOTE 2: why is this a enum with numbers to begin with ?
enum MeasurementStatusEnum {
  OPTIMAL = 1,
  HIGH_TEMP = -1,
  LOW_TEMP = -2,
  HIGH_HUMIDITY = -3,
  NO_DATA = -100,
}

export const mdm = {
  devices: {
    temperature: '#EADDFF',
    humidity: '#543170',
    [MeasurementStatusEnum.OPTIMAL]: {
      background: '#D2FFCB',
      font: '#000',
    },
    [MeasurementStatusEnum.HIGH_HUMIDITY]: {
      background: '#F6CF68',
      font: '#000',
    },
    [MeasurementStatusEnum.HIGH_TEMP]: {
      background: '#EB4949',
      font: '#FFF',
    },
    [MeasurementStatusEnum.LOW_TEMP]: {
      background: '#98BBFF',
      font: '#000',
    },
    [MeasurementStatusEnum.NO_DATA]: {
      background: '#a2a2a2',
      font: '#000',
    },
  },
};

export interface ScheduleTheme {
  colors: typeof colors;
  virtual_spaces?: {
    level1?: VirtualSpaceTheme;
    level2?: VirtualSpaceTheme;
    level3?: VirtualSpaceTheme;
    level4?: VirtualSpaceTheme;
  };
  task?: SimplePaletteColorOptions;
}

type Palette = typeof ownPalette;

declare module '@mui/material/styles' {
  interface Theme extends Palette {
    colors: typeof colors;
    schedule: typeof schedule;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Record<TypographyVariant, true> {}
}

export const ownPalette = {
  colors,
  schedule,
  virtual_spaces: {
    level1: {
      background: {
        main: '#ffffff',
      },
      text: {
        main: '#000000',
      },
    },
    level2: {
      background: {
        main: '#F9F7FA',
      },
      text: {
        main: '#000000',
      },
    },
    level3: {
      background: {
        main: '#F9F7FA',
      },
      text: {
        main: '#000000',
      },
    },
    level4: {
      background: {
        main: '#F9F7FA',
      },
      text: {
        main: '#000000',
      },
    },
  },
};

const typographyVariants = {
  'Web/Display/Large': {
    fontSize: '3.5625rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '4rem',
  },
  'Web/Display/Small': {
    fontSize: '2.25rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '2.75rem',
  },
  'Web/Label/Medium': {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1rem',
    letterSpacing: '0.03125rem',
  },
  'Web/Label/Small': {
    fontSize: '0.6875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1rem',
    letterSpacing: '0.03125rem',
  },
  'Web/Headline/Large': {
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '2.5rem',
  },
  'Web/Body/Medium': {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
    letterSpacing: '0.01563rem',
  },
  'Web/Body/Small': {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
  },
  'Web/Title/Medium': {
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.5rem',
    letterSpacing: '0.00938rem',
  },
  'Mobile/Title/Medium': {
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
  },
  'Mobile/label/large': {
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.25rem',
    letterSpacing: '0.00625rem',
  },
};

type TypographyVariantMap = typeof typographyVariants;
type TypographyVariant = keyof TypographyVariantMap;

const theme = createTheme({
  typography: {
    fontFamily: ['Graphik', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    ...typographyVariants,
  },
  palette: {
    primary: {
      main: '#4E2C6A',
      light: '#EADDFF',
    },
    secondary: {
      main: '#745086',
      light: '#DCD4E6',
    },
  },
  ...ownPalette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          // Intentional instead of 50%, we want rounded, not oval
          borderRadius: '100px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: '#000000',
          fontWeight: 500,
          fontSize: '0.875rem',
          flex: 1,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          width: '100%',
          minWidth: '368px',
          margin: '0 -8px',
          '& button:last-of-type': {
            borderTopRightRadius: '2rem',
            borderBottomRightRadius: '2rem',
          },
          '& button:first-of-type': {
            borderTopLeftRadius: '2rem',
            borderBottomLeftRadius: '2rem',
          },
          '& .MuiButton-root': {
            borderColor: '#D9D6D2',
            borderRadius: 0,
            fontSize: '0.75rem',
            color: '#21005D',
            backgroundColor: '#ffffff',
            '&:hover': {
              borderColor: '#d9d6d2',
            },
            '&[data-selected="true"]': {
              backgroundColor: colors['M3/sys/light/secondary-container'],
            },
            '&[data-variant="planned"]': {
              backgroundColor: '#E4EFFF',
              color: '#21005D',
            },
            '&[data-variant="in_progress"]': {
              backgroundColor: '#6E9CF2',
              color: '#21005D',
            },
            '&[data-variant="completed"]': {
              backgroundColor: '#5AB24C',
              color: '#FFFFFF',
            },
            '&[data-border-variant="planned"]': {
              borderColor: '#85B0EC',
            },
            '&[data-border-variant="in_progress"]': {
              borderColor: '#D0BCFF',
            },
            '&[data-border-variant="completed"]': {
              borderColor: '#4AB638',
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '5px 1rem',
          fontSize: '0.75rem',
          color: '#21005D',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: colors['M3/sys/light/secondary-container'],
          },
          '&.Mui-selected:hover': {
            color: '#000000',
            backgroundColor: `${colors['M3/sys/light/secondary-container']}EE`,
          },
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          width: '100%',

          '& button:last-of-type': {
            borderTopRightRadius: '2rem',
            borderBottomRightRadius: '2rem',
          },
          '& button:first-of-type': {
            borderTopLeftRadius: '2rem',
            borderBottomLeftRadius: '2rem',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
        shrink: {
          fontWeight: 500,
          fontSize: '0.875rem',
          transform: 'translate(14px, -7px) scale(0.75)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: { fontSize: '0.875rem' },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#62615F',
          '&.Mui-checked': {
            color: '#2C2A29',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '2rem',
          paddingBottom: '1rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 2rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '2rem',
          paddingTop: '1rem',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderColor: '#79747E',
        },
      },
    },

    MuiDatePicker: {
      defaultProps: {
        displayWeekNumber: true,
      },
    },
  },
});

export { theme };
