/* eslint-disable no-restricted-syntax */
import { arrayify } from './array';

export function omit<Obj extends object, Key extends keyof Obj>(obj: Obj, keys: Key | Key[]): Omit<Obj, Key> {
  const keysArray = arrayify(keys);
  return Object.fromEntries(Object.entries(obj).filter(([key]) => !keysArray.includes(key as Key))) as Omit<Obj, Key>;
}

export function pick<Obj extends object, Key extends keyof Obj>(obj: Obj, keys: Key | Key[]): Pick<Obj, Key> {
  const keysArray = arrayify(keys);
  return Object.fromEntries(keysArray.map((key) => [key, obj[key]])) as Pick<Obj, Key>;
}

export function omitEmpty<TObj extends Record<string, unknown>>(obj: TObj) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined)) as Partial<TObj>;
}

export function copyKeys<T, U extends Partial<T>>(keysFrom: T, valuesFrom: U) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newInput: any = {};
  for (const key in keysFrom) {
    if (key in valuesFrom) {
      newInput[key] = valuesFrom[key];
    }
  }
  return newInput as T;
}

export function isObject(item: unknown): item is Record<string, unknown> {
  return item != null && typeof item === 'object' && !Array.isArray(item);
}

export function hasOwnProperty(obj: unknown, key: string) {
  return isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key);
}

export function mergeDeep<Obj extends object>(target: Obj, ...sources: Array<Partial<Obj>>): Obj {
  if (sources.length === 0) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (target[key] == null) Object.assign(target, { [key]: {} });
        mergeDeep(target[key as keyof Obj] as Obj, (source[key] as Partial<Obj>) ?? {});
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
