import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface State {
  projectId: string;
  chatUnreadCount: number | null;
  chatOpen: boolean;
}

interface Actions {
  actions: {
    setProject: (projectId: string) => void;
    clearProject: () => void;
    setChatUnreadCount: (count: number) => void;
    toggleChat: () => void;
  };
}

const initialState: State = {
  projectId: '',
  chatUnreadCount: null,
  chatOpen: false,
};

const useNavState = create(
  persist<State & Actions>(
    (set) => ({
      ...initialState,
      actions: {
        setProject: (projectId: string) => {
          set({ projectId });
        },
        clearProject: () => {
          set({ projectId: '' });
        },
        setChatUnreadCount: (chatUnreadCount: number | null) => {
          set({ chatUnreadCount });
        },
        toggleChat: () => {
          set((state) => ({ chatOpen: !state.chatOpen }));
        },
      },
    }),
    {
      name: 'navStore',
      // NOTE: Partialize the state to only persist the chatNotifications
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      partialize: (state) => ({
        chatUnreadCount: state.chatUnreadCount,
      }),
    },
  ),
);

export const useProjectId = () => useNavState((state) => state.projectId);
export const useChatUnreadCount = () => useNavState((state) => state.chatUnreadCount);
export const useChatOpen = () => useNavState((state) => state.chatOpen);
export const useSetNavProject = () => useNavState((state) => state.actions.setProject);
export const useClearNavProject = () => useNavState((state) => state.actions.clearProject);
export const useSetChatUnreadCount = () => useNavState((state) => state.actions.setChatUnreadCount);
export const useToggleChat = () => useNavState((state) => state.actions.toggleChat);
