import * as notistack from 'notistack';

import { CustomSnackbarContent } from '~/components/CustomToast/CustomToast';

const commonOptions: notistack.OptionsObject = {
  autoHideDuration: 5000,
  preventDuplicate: true,
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
};

function createToaster<Variant extends notistack.VariantType>(variant: Variant) {
  return (message: notistack.SnackbarMessage, options: Omit<notistack.OptionsObject<Variant>, 'variant'> = {}) =>
    notistack.enqueueSnackbar<Variant>(message, { variant, ...options } as notistack.OptionsWithExtraProps<Variant>);
}

export default function toast(
  message: notistack.SnackbarMessage,
  options: notistack.OptionsObject = {},
  extraProps: notistack.OptionsWithExtraProps<'custom'> = { link: { href: '', title: '' } },
) {
  return notistack.enqueueSnackbar(message, { ...commonOptions, ...options, ...extraProps });
}

toast.error = createToaster('error');
toast.success = createToaster('success');
toast.info = createToaster('info');
toast.Provider = function ToastProvider(props: React.PropsWithChildren) {
  return <notistack.SnackbarProvider {...props} {...commonOptions} Components={{ custom: CustomSnackbarContent }} />;
};
