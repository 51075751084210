import { type WorkPackageMoveInput } from '~/@generated/graphql';

export interface Identifiable {
  id: string;
  virtual_space_id?: string | null;
}

export function mapDataToMoveResizeInput(data: {
  id: string;
  virtual_space_id?: string | null;
  planned_start: string;
  planned_end: string;
}): WorkPackageMoveInput {
  const value: WorkPackageMoveInput = {
    id: data.id,
    planned_start: data.planned_start,
    planned_end: data.planned_end,
  };

  if (data.virtual_space_id !== undefined) {
    value.virtual_space_id = data.virtual_space_id;
  }

  return value;
}
