/* eslint-disable no-restricted-syntax */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Divider, ListItemText, MenuItem, styled, Tooltip } from '@mui/material';

import { useHeaderProjectQuery } from '~/@generated/graphql';
import { appConfig } from '~/config/config';
import { routes } from '~/config/routes';
import { usePreferredAccountIdPreference } from '~/data/userPreferences';
import { useGetPermissionsToAccount, useIsSystemAdmin, usePermissionsToSite } from '~/permissions';
import { useProjectId } from '~/store';

import { IconSettings } from '../Icons/icons';
import Menu from '../Menu';

import { IconNavButton } from './Header.NavButtons';

const ReadonlyItem = styled(MenuItem)`
  &.MuiMenuItem-root.Mui-disabled {
    opacity: 0.8;
    padding-bottom: 2px;
    padding-top: 2px;
    font-size: 0.875rem;
  }
`;

function SettingsMenuComponent() {
  const { t: getGenericText } = useTranslation('generic', { keyPrefix: 'generic' });
  const { t } = useTranslation('generic', { keyPrefix: 'Header.SettingsMenu' });
  const navigate = useNavigate();
  const projectId = useProjectId();
  const menu = Menu.useMenu({ id: 'settings-menu' });

  const { data } = useHeaderProjectQuery({ projectId }, { enabled: projectId !== '' });
  const preferredAccountId = usePreferredAccountIdPreference().data;

  const siteId = data?.project?.site_id;
  const projectAccountId = data?.project?.account_id;
  const canUpdateSite = usePermissionsToSite(siteId ?? '').update;
  const getPermissionToAccount = useGetPermissionsToAccount();
  const isSystemAdmin = useIsSystemAdmin();

  const renderAccountSettingsButton = () => {
    if (preferredAccountId == null || !getPermissionToAccount(preferredAccountId).update) {
      return null;
    }

    return (
      <MenuItem
        aria-label="account-settings"
        onClick={() => {
          navigate(routes.mdm(preferredAccountId).root());
          menu.closeMenu();
        }}
      >
        <ListItemText disableTypography>{t('items.account')}</ListItemText>
      </MenuItem>
    );
  };

  const renderAdminPageButton = () => {
    if (!isSystemAdmin) {
      return null;
    }
    return (
      <MenuItem
        onClick={() => {
          navigate(routes.adminPage().root());
          menu.closeMenu();
        }}
      >
        <ListItemText disableTypography>{t('items.systemAdmin')}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <>
      <Tooltip title={t('title')}>
        <IconNavButton {...menu.anchorProps} aria-label="settings-btn">
          <IconSettings />
        </IconNavButton>
      </Tooltip>
      <Menu.Root {...menu.rootProps}>
        {renderAccountSettingsButton()}

        {renderAdminPageButton()}

        {canUpdateSite && (
          <MenuItem
            disabled={projectAccountId == null || siteId == null}
            onClick={() => {
              if (projectAccountId != null && siteId != null) {
                navigate(routes.sitePage(siteId).root());
                menu.closeMenu();
              }
            }}
          >
            {t('items.site')}
          </MenuItem>
        )}

        <Divider />

        <ReadonlyItem disabled>{getGenericText('version', { version: APP_VERSION })}</ReadonlyItem>
        {!appConfig.isEnv('production', 'staging') && <ReadonlyItem disabled> Build: {BUILD_HASH}</ReadonlyItem>}
      </Menu.Root>
    </>
  );
}

export const SettingsMenu = memo(SettingsMenuComponent);
