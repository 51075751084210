import { useMemo } from 'react';
import { Check as ReadyIcon, Settings as LoadingIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

import { useLoadingState, LoadingState } from '../../hooks';
import { colors, northstarColors } from '../../config';

const StyledButton = styled(LoadingButton)<{ playAnimation: boolean }>`
  .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 1rem;
    animation: ${({ playAnimation }) => (playAnimation ? 'rotate 1s infinite linear' : 'none')};
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export interface PrimaryButtonProps extends React.ComponentProps<typeof LoadingButton> {
  children?: React.ReactNode | string;
  disabled?: boolean;
  onClick?: (() => void) | undefined | React.MouseEventHandler<HTMLButtonElement>;
  minWidth?: number;
  backgroundColor?: string;
  hoverColor?: string;
  fontColor?: string;
  value?: string | number;
  marginBottom?: number | string;
  name?: string;
  type?: 'button' | 'submit' | 'reset';
  stateLabels?: Partial<Record<LoadingState, string>>;
}

function PrimaryButton({
  children,
  onClick,
  disabled,
  minWidth,
  backgroundColor,
  fontColor,
  hoverColor,
  value,
  marginBottom,
  name,
  type = 'submit',
  loading,
  startIcon,
  stateLabels,
  ...rest
}: PrimaryButtonProps) {
  const state = useLoadingState(loading);

  const icon = useMemo(() => {
    if (state === 'loading') return <LoadingIcon />;
    if (state === 'ready') return <ReadyIcon />;
    return startIcon;
  }, [state, startIcon]);

  return (
    <StyledButton
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      value={value}
      name={name}
      endIcon={<span />}
      type={type}
      startIcon={icon}
      playAnimation={state === 'loading'}
      {...rest}
      sx={[
        {
          marginBottom: marginBottom ?? 0,
          color: fontColor ?? '#FFFFFF',
          textTransform: 'none',
          borderRadius: 28,
          minWidth: minWidth ?? 120,
          padding: '0.5rem 1rem',
          background: backgroundColor ?? colors['M3/sys/light/primary'],

          ':hover': {
            background: hoverColor ?? '#9c8acc',
          },
        },
      ]}
    >
      {stateLabels?.[state] ?? children}
    </StyledButton>
  );
}

export { PrimaryButton };
