import { forwardRef } from 'react';
import { Box, Link, Stack, styled } from '@mui/material';
import { type CustomContentProps, SnackbarContent } from 'notistack';

import { IconCheck } from '../Icons/icons';

interface SuccessToastWithLinkProps extends CustomContentProps {
  link: {
    href: string;
    title: string;
  };
}

const Container = styled(Box)`
  display: flex;
  padding: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 10px;
  max-width: 288px;
  background: #fff;
  gap: 1.5rem;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
`;

export const CustomSnackbarContent = forwardRef<HTMLDivElement, SuccessToastWithLinkProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ id, message, link, ...other }, ref) => {
    return (
      <SnackbarContent role="alert" ref={ref} {...other}>
        <Container>
          <IconCheck />
          <Stack>
            <Box>{message}</Box>
            <Link href={link.href} underline="none" color="#6750A4">
              {link.title}
            </Link>
          </Stack>
        </Container>
      </SnackbarContent>
    );
  },
);
