export default function Svg({
  width,
  height,
  ...rest
}: React.ComponentProps<'svg'> & { width: number | string; height: number | string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...rest}
    />
  );
}
