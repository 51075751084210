import { useCallback, useEffect, useMemo } from 'react';
import { isMacOs } from 'react-device-detect';
import { initReactI18next, useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs, defaultLanguage, en, es, fi, no, type SupportedLanguage } from '@sitedrive/common';
import { useLatest } from '@sitedrive/design-system';
import { formatDistanceToNow, formatDistanceToNowStrict, setDefaultOptions } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';
import i18n, { type TFuncKey } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { type Mutation } from '~/@generated/graphql';
import genericCz from '~/locales/cs/generic.json';
import mdmCz from '~/locales/cs/mdm.json';
import rawMutationsCz from '~/locales/cs/mutations.json';
import scheduleCz from '~/locales/cs/schedule.json';

import genericEn from './en/generic.json';
import mdmEn from './en/mdm.json';
import rawMutationsEn from './en/mutations.json';
import scheduleEn from './en/schedule.json';
import threesixtyEn from './en/threesixty.json';
import genericEs from './es/generic.json';
import mdmEs from './es/mdm.json';
import rawMutationsEs from './es/mutations.json';
import scheduleEs from './es/schedule.json';
import genericFi from './fi/generic.json';
import mdmFi from './fi/mdm.json';
import rawMutationsFi from './fi/mutations.json';
import scheduleFi from './fi/schedule.json';
import threesixtyFi from './fi/threesixty.json';
import genericNo from './no/generic.json';
import mdmNo from './no/mdm.json';
import rawMutationsNo from './no/mutations.json';
import scheduleNo from './no/schedule.json';

const defaultNS = 'generic';

type MutationTranslation = Record<keyof Mutation, string>;

/**
 * translations for each mutation, this type ensures that all mutations are translated.
 * the mutation.json files should still contain removed/deleted mutation translations
 */
const mutationsEn = rawMutationsEn satisfies MutationTranslation;
const mutationsFi = rawMutationsFi satisfies MutationTranslation;
const mutationsEs = rawMutationsEs satisfies MutationTranslation;
const mutationsCz = rawMutationsCz satisfies MutationTranslation;
const mutationsNo = rawMutationsNo satisfies MutationTranslation;

const resources = {
  [fi]: {
    generic: genericFi,
    schedule: scheduleFi,
    threesixty: threesixtyFi,
    mdm: mdmFi,
    mutations: mutationsFi,
  },
  [en]: {
    generic: genericEn,
    schedule: scheduleEn,
    threesixty: threesixtyEn,
    mdm: mdmEn,
    mutations: mutationsEn,
  },
  [es]: {
    generic: genericEs,
    schedule: scheduleEs,
    mdm: mdmEs,
    mutations: mutationsEs,
  },
  [cs]: {
    generic: genericCz,
    schedule: scheduleCz,
    mdm: mdmCz,
    mutations: mutationsCz,
  },
  [no]: {
    generic: genericNo,
    schedule: scheduleNo,
    mdm: mdmNo,
    mutations: mutationsNo,
  },
} as const;

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
    defaultNS: typeof defaultNS;
    resources: (typeof resources)[typeof defaultLanguage];
  }
}

await i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: defaultLanguage,
    returnNull: false,
    debug: true,
    load: 'languageOnly',
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

const locales = {
  en: dateFnsLocales.enUS,
  fi: dateFnsLocales.fi,
  es: dateFnsLocales.es,
  cs: dateFnsLocales.cs,
  no: dateFnsLocales.nn,
} as const;

const formatDistanceShortLocale = {
  en: {
    lessThanXSeconds: '{{count}} s',
    xSeconds: '{{count}} s',
    halfAMinute: '30 s',
    lessThanXMinutes: '{{count}} min',
    xMinutes: '{{count}} min',
    aboutXHours: '{{count}} h',
    xHours: '{{count}} h',
    xDays: '{{count}} d',
    aboutXWeeks: '{{count}} w',
    xWeeks: '{{count}} w',
    aboutXMonths: '{{count}} mon',
    xMonths: '{{count}} mon',
    aboutXYears: '{{count}} y',
    xYears: '{{count}} y',
    overXYears: '{{count}} y',
    almostXYears: '{{count}} y',
  },
  fi: {
    lessThanXSeconds: '{{count}} s',
    xSeconds: '{{count}} s',
    halfAMinute: '30 s',
    lessThanXMinutes: '{{count}} min',
    xMinutes: '{{count}} min',
    aboutXHours: '{{count}} h',
    xHours: '{{count}} h',
    xDays: '{{count}} pv',
    aboutXWeeks: '{{count}} vk',
    xWeeks: '{{count}} vk',
    aboutXMonths: '{{count}} kk',
    xMonths: '{{count}} kk',
    aboutXYears: '{{count}} v',
    xYears: '{{count}} v',
    overXYears: '{{count}} v',
    almostXYears: '{{count}} v',
  },
  es: {
    lessThanXSeconds: '{{count}} s',
    xSeconds: '{{count}} s',
    halfAMinute: '30 s',
    lessThanXMinutes: '{{count}} min',
    xMinutes: '{{count}} min',
    aboutXHours: '{{count}} h',
    xHours: '{{count}} h',
    xDays: '{{count}} d',
    aboutXWeeks: '{{count}} sem',
    xWeeks: '{{count}} sem',
    aboutXMonths: '{{count}} mes',
    xMonths: '{{count}} mes',
    aboutXYears: '{{count}} a',
    xYears: '{{count}} a',
    overXYears: '{{count}} a',
    almostXYears: '{{count}} a',
  },
  cs: {
    lessThanXSeconds: '{{count}} s',
    xSeconds: '{{count}} s',
    halfAMinute: '30 s',
    lessThanXMinutes: '{{count}} min',
    xMinutes: '{{count}} min',
    aboutXHours: '{{count}} h',
    xHours: '{{count}} h',
    xDays: '{{count}} d',
    aboutXWeeks: '{{count}} t',
    xWeeks: '{{count}} t',
    aboutXMonths: '{{count}} m',
    xMonths: '{{count}} m',
    aboutXYears: '{{count}} r',
    xYears: '{{count}} r',
    overXYears: '{{count}} r',
    almostXYears: '{{count}} r',
  },
  no: {
    lessThanXSeconds: '{{count}} s',
    xSeconds: '{{count}} s',
    halfAMinute: '30 s',
    lessThanXMinutes: '{{count}} min',
    xMinutes: '{{count}} min',
    aboutXHours: '{{count}} t',
    xHours: '{{count}} t',
    xDays: '{{count}} d',
    aboutXWeeks: '{{count}} u',
    xWeeks: '{{count}} u',
    aboutXMonths: '{{count}} mnd',
    xMonths: '{{count}} mnd',
    aboutXYears: '{{count}} år',
    xYears: '{{count}} år',
    overXYears: '{{count}} år',
    almostXYears: '{{count}} år',
  },
};

const getDistanceFormatter = (language: SupportedLanguage) => {
  const localeMap = formatDistanceShortLocale[language];
  return (token: keyof typeof localeMap, count: string | number) =>
    localeMap[token].replace('{{count}}', count.toString());
};

function addFormatter(
  format: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (params: { locale: Locale; lng: SupportedLanguage }) => (value: any) => string,
) {
  i18n.services.formatter?.addCached(format, (lang) => {
    const lng = lang as SupportedLanguage;
    const locale = locales[lng];
    return fn({ locale, lng });
  });
}

addFormatter('distanceToNow', ({ locale }) => {
  return (value) => formatDistanceToNow(value, { locale, addSuffix: true });
});

addFormatter('distanceToNowStrictShort', ({ lng, locale }) => {
  const formatDistance = getDistanceFormatter(lng);
  return (value) => formatDistanceToNowStrict(value, { locale: { ...locale, formatDistance } });
});

addFormatter('durationHours', ({ lng }) => {
  const formatter = new Intl.DisplayNames([lng], { type: 'dateTimeField', style: 'narrow' });
  return (value: number) => `${value} ${formatter.of('hour') ?? 'h'}`;
});

addFormatter('durationDays', ({ lng }) => {
  const formatter = new Intl.DisplayNames([lng], { type: 'dateTimeField', style: 'narrow' });
  return (value: number) => `${value} ${formatter.of('day') ?? 'd'}`;
});

addFormatter('language', ({ lng }) => {
  const formatter = new Intl.DisplayNames([lng], { type: 'language' });
  return (value) => formatter.of(value) ?? value;
});

addFormatter('country', ({ lng }) => {
  const formatter = new Intl.DisplayNames([lng], { type: 'region' });
  return (value) => (typeof value === 'string' ? formatter.of(value.toUpperCase()) ?? value : value);
});

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const lng = useTranslation().i18n.language as SupportedLanguage;
  const locale = locales[lng];

  useEffect(() => {
    setDefaultOptions({ locale });
  }, [locale]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
}

export function useCurrentLanguage() {
  return useTranslation().i18n.language as SupportedLanguage;
}

export function usePercentFormatter(options: Intl.NumberFormatOptions = {}) {
  const latestOptions = useLatest(options);
  const language = useCurrentLanguage();
  return useMemo(
    () => new Intl.NumberFormat(language, { ...latestOptions.current, style: 'percent' }).format,
    [language, latestOptions],
  );
}

export function useDistanceFormatter() {
  const language = useCurrentLanguage();
  return useMemo(() => getDistanceFormatter(language), [language]);
}

export function useTimeFormatter() {
  const language = useCurrentLanguage();
  return useCallback(
    (date: Date, options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' }) => {
      return date.toLocaleString(language, options);
    },
    [language],
  );
}

export function useShortcutText() {
  const { t: getShortcutText } = useTranslation('generic', { keyPrefix: 'keyboardShortcuts' });
  type Key = TFuncKey<'generic', 'keyboardShortcuts'>;
  return useCallback((key: Key) => getShortcutText(key, { context: isMacOs ? 'mac' : undefined }), [getShortcutText]);
}
