import { css, IconButton, styled } from '@mui/material';

import { assertNever } from '~/utils/assertions';

type IconButtonVariant = 'filled' | 'tonal' | 'outlined' | 'transparent';

/**
 * Material design v3 contained icon button
 */
export const ContainedIconButton = styled(IconButton)<{ variant?: IconButtonVariant; isActive?: boolean }>(
  ({ theme, variant = 'filled', isActive = false }) => {
    const primaryColor = theme.colors['M3/sys/light/primary'];
    const lightText = theme.palette.primary.contrastText;
    switch (variant) {
      case 'filled':
        return css`
          background-color: ${primaryColor};
          color: ${lightText};

          :hover {
            background-color: ${primaryColor};
          }
        `;

      case 'tonal':
        return css`
          background-color: ${theme.colors['M3/sys/light/secondary-container']};
          color: #1d192b;

          :hover {
            background-color: rgba(29, 25, 43, 0.08);
          }

          :disabled {
            color: rgba(28, 27, 31, 0.12);
            background-color: rgba(29, 25, 43, 0.08);
          }
        `;

      case 'outlined':
        return css`
          border: 1px solid #79747e;
          color: #49454f;

          :hover {
            background-color: rgba(73, 69, 79, 0.08);
          }

          :disabled {
            border-color: rgba(28, 27, 31, 0.12);
            color: rgba(28, 27, 31, 0.12);
          }
        `;

      case 'transparent':
        return css`
          color: #1d192b;

          ${isActive &&
          css`
            background-color: ${primaryColor};
            color: ${lightText};
          `}

          :hover {
            background-color: ${theme.colors['M3/sys/light/secondary-container']};
          }

          :disabled {
            color: rgba(28, 27, 31, 0.12);
          }
        `;

      default:
        return assertNever(variant);
    }
  },
);
