import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';
import { enableMapSet } from 'immer';

import FirebaseAuthProvider from './components/BaseApp/FirebaseAuthProvider';
import { BugsnagErrorBoundary } from './config/bugsnag';
import { firebaseConfig } from './config/firebase';
import { QueryProvider } from './config/queries';
import toast from './utils/toast';
import App from './App';
import { LanguageProvider } from './locales';

import 'unfonts.css';

enableMapSet();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <DndProvider debugMode backend={HTML5Backend}>
    <BugsnagErrorBoundary>
      <LanguageProvider>
        <toast.Provider>
          <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <FirebaseAuthProvider>
              <QueryProvider>
                <React.StrictMode>
                  <App />
                </React.StrictMode>
              </QueryProvider>
            </FirebaseAuthProvider>
          </FirebaseAppProvider>
        </toast.Provider>
      </LanguageProvider>
    </BugsnagErrorBoundary>
  </DndProvider>,
);

// test by emitting: `window.dispatchEvent(new Event('vite:preloadError'));`
// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (_event) => {
  window.location.reload();
});
