export async function waitForElement<El extends HTMLElement | SVGElement>(
  getElement: () => El | null,
): Promise<El | null> {
  let tries = 0;
  return new Promise((resolve) => {
    const checkElement = () => {
      if (tries > 20) {
        resolve(null);
        return;
      }
      const element = getElement();
      if (element == null) {
        tries += 1;
        setTimeout(checkElement, 100);
      } else {
        resolve(element);
      }
    };
    checkElement();
  });
}

export function isElement(element: unknown): element is HTMLElement {
  return element instanceof HTMLElement;
}

export function isInsideElement(element: HTMLElement | SVGElement, target: HTMLElement | SVGElement) {
  if (element === target) return true;
  if (element?.parentElement == null) return false;
  return isInsideElement(element.parentElement, target);
}
