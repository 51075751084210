import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppBar, Box, styled, Toolbar, Tooltip } from '@mui/material';
import { colors } from '@sitedrive/design-system';

import SitedriveTextLogo from '~/assets/logo_with_text.svg?react';
import { appConfig } from '~/config/config';
import { useChatOpen, useChatUnreadCount, useToggleChat } from '~/store';

import { IconClose, IconCustomerSupport, IconHome, IconIosShare, IconQuestionMark } from '../Icons/icons';

import { IconNavButton, IconNavLink } from './Header.NavButtons';
import { ProjectMenu } from './Header.ProjectMenu';
import { SettingsMenu } from './Header.SettingsMenu';
import { UserSettingsMenu } from './Header.UserSettingsMenu';

export const HEADER_BAR_HEIGHT_IN_PX = 51;

const enabledFeatures = appConfig.features.header;
const Logo = styled(SitedriveTextLogo)`
  height: 40px;
  margin-bottom: 1px;
  color: white;
`;

const SettingsContainer = styled('div')`
  display: flex;
  align-items: stretch;
  align-self: stretch;
`;

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  box-shadow: none;
`;

const StyledToolbar = styled(Toolbar)`
  && {
    min-height: ${HEADER_BAR_HEIGHT_IN_PX}px;
    gap: 1rem;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const HubspotChatNotification = styled('div')`
  position: absolute;
  top: 4px;
  right: 8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${colors['M3/sys/light/error']};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
`;

/**
 * opens a minimal ui popup window that is half the width of the current window and height of the current viewport
 * and is positioned almost at the bottom right corner of the screen (question mark icon visible)
 */
function openSupportPage() {
  const halfWidth = window.innerWidth / 2;

  const windowFeatures = {
    popup: true,
    width: halfWidth,
    height: window.innerHeight,
    left: window.screenX + halfWidth - 100,
    top: window.screen.height - window.innerHeight,
  };

  const windowFeaturesString = Object.entries(windowFeatures)
    .map(([key, value]) => `${key}=${String(value)}`)
    .join(',');

  window.open(appConfig.supportUrl, '_blank', windowFeaturesString);
}

function Header() {
  const { t } = useTranslation('generic');
  const isChatOpen = useChatOpen();
  const toggleChat = useToggleChat();
  const unreadCount = useChatUnreadCount();
  const showNotifications = unreadCount !== null && unreadCount > 0;
  return (
    <Box sx={{ display: 'flex' }}>
      <StyledAppBar position="fixed" role="banner" color="transparent">
        <StyledToolbar>
          <StyledLink to="/">
            <Logo />
          </StyledLink>

          <IconNavLink to="/">
            <IconHome />
          </IconNavLink>

          <ProjectMenu />
          <SettingsContainer>
            <Tooltip title={t('Header.chat')}>
              <IconNavButton
                onClick={toggleChat}
                sx={{
                  position: 'relative',
                  color: !isChatOpen ? '#9CBFF4' : 'inherit',
                }}
              >
                {isChatOpen ? <IconClose /> : <IconCustomerSupport />}
                {showNotifications && <HubspotChatNotification>{unreadCount}</HubspotChatNotification>}
              </IconNavButton>
            </Tooltip>
            {enabledFeatures.share && (
              <IconNavButton>
                <IconIosShare />
              </IconNavButton>
            )}
            <SettingsMenu />
            <UserSettingsMenu />
            <IconNavButton onClick={openSupportPage}>
              <IconQuestionMark />
            </IconNavButton>
          </SettingsContainer>
        </StyledToolbar>
      </StyledAppBar>
    </Box>
  );
}

export default memo(Header);
