import { useTranslation } from 'react-i18next';
import { PrimaryButton, type PrimaryButtonProps } from '@sitedrive/design-system';

function SaveButton(props: PrimaryButtonProps) {
  const { t } = useTranslation();
  return (
    <PrimaryButton
      {...props}
      stateLabels={{
        idle: t('generic.save'),
        loading: t('generic.saving'),
        ready: t('generic.saved'),
      }}
    />
  );
}

export default SaveButton;
