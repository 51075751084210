import { useEffect } from 'react';
import { useLatest, useLatestFunction } from '@sitedrive/design-system';

import { useCurrentUserQuery, useUpsertUserPreferenceByKeyMutation } from '~/@generated/graphql';
import { useInvalidateQueryKeys, useRefetchQueryKeys } from '~/config/queries';
import { usePermissionsLoaded } from '~/permissions';
import { operationQueryKeys } from '~/utils/queries';

export interface UserPreferenceKeyValue {
  favorite_site_ids: string[];
  latest_site_ids: Array<{ id: string; visited_at: string }>;
  preferred_account_id: string | undefined;
  favorite_snippet_ids: string[];
}

type UserPreferenceKeys = keyof UserPreferenceKeyValue;

function useUserPreference<Key extends UserPreferenceKeys>(key: Key, defaultValue: UserPreferenceKeyValue[Key]) {
  const permissionsLoaded = usePermissionsLoaded();
  const { data: userData, ...query } = useCurrentUserQuery(undefined, { enabled: permissionsLoaded });
  const userPreferences = userData?.current_user?.preferences;
  const data = (userPreferences?.find((pref) => (pref.key as UserPreferenceKeys) === key)?.value ??
    defaultValue) as UserPreferenceKeyValue[Key];
  const invalidateQueries = useInvalidateQueryKeys();
  const refetchQueries = useRefetchQueryKeys();
  const userPreferenceMutation = useUpsertUserPreferenceByKeyMutation({
    onSuccess: ({ upsertUserPreferenceByKey }) => {
      const upsertedKey = upsertUserPreferenceByKey.key as keyof UserPreferenceKeyValue;
      if (upsertedKey === 'preferred_account_id') {
        refetchQueries(operationQueryKeys.LandingPageSites);
      }
      invalidateQueries(operationQueryKeys.CurrentUser);
    },
  });

  const mutate = useLatestFunction((value: UserPreferenceKeyValue[Key]) => {
    userPreferenceMutation.mutate({ input: { key, value } });
  });

  const mutateAsync = useLatestFunction(async (value: UserPreferenceKeyValue[Key]) => {
    return userPreferenceMutation.mutateAsync({ input: { key, value } });
  });

  return {
    ...query,
    data,
    mutate,
    mutateAsync,
    isMutating: userPreferenceMutation.isPending,
  };
}

export function useLatestSitesPreference() {
  return useUserPreference('latest_site_ids', []);
}

export function useFavoriteSitesPreference() {
  return useUserPreference('favorite_site_ids', []);
}

export function usePreferredAccountIdPreference() {
  return useUserPreference('preferred_account_id', undefined);
}

export function useFavoriteSnippetIds() {
  return useUserPreference('favorite_snippet_ids', []);
}

export function useAutoUpdatePreferredAccountIdPreference(accountId: string) {
  const { mutate, data, isMutating } = usePreferredAccountIdPreference();
  const latestAccountId = useLatest(data);
  const latestMutating = useLatest(isMutating);
  useEffect(() => {
    if (!latestMutating.current && latestAccountId.current !== accountId) {
      mutate(accountId);
    }
  }, [accountId, latestAccountId, latestMutating, mutate]);
}
