/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { type Mutation, type QueryCache, type QueryClient } from '@tanstack/react-query';
import { create } from 'zustand';

import {
  type CloneWorkPackageInput,
  type CopyPasteWorkPackageInput,
  type RemoveWorkPackageInput,
  type RemoveWorkPackagesMutation,
  type useCloneWorkPackagesMutation,
  type useCopyPasteWorkPackagesMutation,
  type useRemoveWorkPackagesMutation,
  useWorkPackagesOrCalendarOrSpacesHasChangedByOtherQuery,
  type WorkPackagesOrCalendarOrSpacesHasChangedByOtherQueryVariables,
} from '~/@generated/graphql';
import { reportError } from '~/config/bugsnag';
import i18n from '~/locales';
import { arrayify, uniq } from '~/utils/array';
import { getWorkPackageId } from '~/utils/id';
import { invariant } from '~/utils/invariant';
import { pick } from '~/utils/object';
import toast from '~/utils/toast';

import { type useScheduleMutations } from '../data/schedule-data.mutations';
import { type ResizeInput } from '../data/schedule-data.types';

import {
  generatePreviousCopyPasteVariables,
  generatePreviousStateMoveOrResizeVariables,
  getQueryData,
} from './undo.helpers';

const UNDO_STACK_LENGTH = 5;
const UNDO_30_MIN_EXPIRY_TIME = 30 * 60 * 1000;

type ScheduleMutations = ReturnType<typeof useScheduleMutations>;
type UndoRemoveWorkPackages = ScheduleMutations['undoRemoveWorkPackages'];
type RemoveWorkPackages = ScheduleMutations['removeWorkPackages'];
type ResizeWorkPackages = ScheduleMutations['resizeOrMoveWorkPackages'];
type RemoveWorkPackagesMut = ReturnType<typeof useRemoveWorkPackagesMutation>['mutateAsync'];
type MutationStatus = 'success' | 'pending' | 'error';

type UndoAction =
  | 'UNDO'
  | 'MOVE_OR_RESIZE_WORK_PACKAGES'
  | 'REMOVE_WORK_PACKAGES'
  | 'CLONE_WORK_PACKAGES'
  | 'COPY_PASTE_WORK_PACKAGES';

export interface UndoMetaData {
  undo_action?: UndoAction;
}

interface BaseStackItem {
  mutationId: number;
  timestamp: Date;
  action: UndoAction;
}

interface MoveOrResizeWorkPackagesStackItem extends BaseStackItem {
  action: 'MOVE_OR_RESIZE_WORK_PACKAGES';
  undo?: { moveOrResizeWorkPackagesVariables: { input: Array<ResizeInput> } };
  redo?: { moveOrResizeWorkPackagesVariables: { input: Array<ResizeInput> } };
}

interface RemoveWorkPackagesStackItem extends BaseStackItem {
  action: 'REMOVE_WORK_PACKAGES';
  undo?: { undoRemoveWorkPackagesVariables: Parameters<UndoRemoveWorkPackages>[0] };
  redo?: { removeWorkPackageVariables: Parameters<RemoveWorkPackages>[0] };
}

interface CopyPasteWorkPackagesStackItem extends BaseStackItem {
  action: 'COPY_PASTE_WORK_PACKAGES';
  undo?: {
    moveOrResizeWorkPackageVariables: { input: Array<ResizeInput> };
    removeWorkPackageVariables: { input: Array<RemoveWorkPackageInput> };
  };
  redo?: { copyPasteWorkPackageVariables: { input: Array<CopyPasteWorkPackageInput> } };
}

interface CloneWorkPackagesStackItem extends BaseStackItem {
  action: 'CLONE_WORK_PACKAGES';
  undo?: { removeWorkPackageVariables: { input: Array<RemoveWorkPackageInput> } };
  redo?: { cloneWorkPackageVariables: { input: CloneWorkPackageInput[] } };
}

type StackItem =
  | MoveOrResizeWorkPackagesStackItem
  | RemoveWorkPackagesStackItem
  | CopyPasteWorkPackagesStackItem
  | CloneWorkPackagesStackItem;

interface UndoMutation {
  mutation: Mutation<unknown, unknown, unknown, unknown>;
  variables: unknown;
}

interface State {
  undoError: { display_name: string } | null;
  scheduleViewId: string | null;
  undoStack: StackItem[];
  redoStack: StackItem[];
  mutationStatusMap: Record<number, MutationStatus>;
  isUndoing: boolean;
}

interface Actions {
  setUndoError: (data: { display_name: string } | null) => void;
  clearData: () => void;
  popUndoStack: () => void;
  popRedoStack: () => void;
  setScheduleViewId: (scheduleViewId: string | null) => void;
  setStatusAndUpdateStack: (
    mutationId: number,
    mutationStatus: MutationStatus,
    state: { data: unknown; mutation: UndoMutation },
    queryCache: QueryCache,
  ) => void;
  removeStackItem: (mutationId: number) => void;
  pushUndoAndClearRedo: (mutation: UndoMutation, queryCache: QueryCache) => void;
  undoOrRedo: (
    actions: {
      MOVE_OR_RESIZE_WORK_PACKAGES: { undo: ResizeWorkPackages; redo: ResizeWorkPackages };
      CLONE_WORK_PACKAGES: {
        undo: RemoveWorkPackagesMut;
        redo: ReturnType<typeof useCloneWorkPackagesMutation>['mutateAsync'];
      };
      COPY_PASTE_WORK_PACKAGES: {
        undo: { update: ResizeWorkPackages; remove: RemoveWorkPackagesMut };
        redo: ReturnType<typeof useCopyPasteWorkPackagesMutation>['mutateAsync'];
      };
      REMOVE_WORK_PACKAGES: { undo: UndoRemoveWorkPackages; redo: RemoveWorkPackages };
    },
    queryClient: QueryClient,
    type: 'undo' | 'redo',
  ) => Promise<void>;
}

export type UndoOrRedoActions = Parameters<Actions['undoOrRedo']>[0];

const queryHasChangesByOtherUser = async (
  variables: WorkPackagesOrCalendarOrSpacesHasChangedByOtherQueryVariables,
  queryClient: QueryClient,
  currentState: State & Actions,
) => {
  let hasChanges = false;

  const vars = { ...variables, ids: uniq(arrayify(variables.ids).map((id) => id.slice(0, 36))) };

  const hasChangesQuery = await queryClient.fetchQuery({
    queryKey: useWorkPackagesOrCalendarOrSpacesHasChangedByOtherQuery.getKey(vars),
    queryFn: () => useWorkPackagesOrCalendarOrSpacesHasChangedByOtherQuery.fetcher(vars),
  });
  const result = await hasChangesQuery();

  if (result.scheduleViewCalendarOrSpacesHasChanged?.has_changed === true) {
    const displayName = result.scheduleViewCalendarOrSpacesHasChanged.display_name ?? i18n.t('generic.unknownUser');
    currentState.setUndoError({ display_name: displayName });
    currentState.clearData();
    hasChanges = true;
  }
  if (result.workPackagesHasChanged?.has_changed === true) {
    const displayName = result.workPackagesHasChanged.display_name ?? i18n.t('generic.unknownUser');
    currentState.setUndoError({ display_name: displayName });
    currentState.clearData();
    hasChanges = true;
  }
  return hasChanges;
};

export const useUndoMiddlewareStore = create<State & Actions>((set, get) => ({
  isUndoing: false,
  undoError: null,
  scheduleViewId: null,
  undoStack: [],
  redoStack: [],
  mutationStatusMap: {},
  setUndoError: (data) => {
    set({ undoError: data != null ? { display_name: data.display_name } : null });
  },
  clearData: () => {
    set({ undoStack: [], redoStack: [], mutationStatusMap: {} });
  },
  popUndoStack: () => {
    set((state) => ({ undoStack: state.undoStack.slice(0, -1) }));
  },
  popRedoStack: () => {
    set((state) => ({ redoStack: state.redoStack.slice(0, -1) }));
  },
  setScheduleViewId: (scheduleViewId: string | null) => {
    set({ scheduleViewId });
  },
  setStatusAndUpdateStack: (mutationId, mutationStatus, { mutation, data }, queryCache) => {
    const { scheduleViewId } = get();
    if (
      scheduleViewId == null ||
      mutation.mutation.meta?.undo_action == null ||
      mutation.mutation.meta.undo_action === 'UNDO'
    ) {
      return;
    }

    if (mutationStatus !== 'success') {
      set((state) => ({ mutationStatusMap: { ...state.mutationStatusMap, [mutationId]: mutationStatus } }));
      return;
    }

    const action = mutation.mutation.meta.undo_action;
    const queue = get().undoStack;

    if (action === 'REMOVE_WORK_PACKAGES') {
      const removeWorkPackagesResponse = (data as RemoveWorkPackagesMutation | undefined)?.removeWorkPackages;
      const removeWorkPackagesInput = mutation.variables as { input: RemoveWorkPackageInput[] } | undefined;
      invariant(removeWorkPackagesInput?.input !== undefined, 'removeWorkPackagesInput should exists at this point');
      invariant(Array.isArray(removeWorkPackagesResponse), 'removeWorkPackagesResponse should exists at this point');

      const queryData = getQueryData(queryCache, scheduleViewId);

      if (queryData == null) {
        return;
      }

      const updatedUndoStack = queue.map((item) => {
        if (item.mutationId === mutation.mutation.mutationId) {
          const updatedItem: RemoveWorkPackagesStackItem = {
            ...item,
            action: 'REMOVE_WORK_PACKAGES',
            undo: { undoRemoveWorkPackagesVariables: removeWorkPackagesResponse },
            redo: { removeWorkPackageVariables: removeWorkPackagesInput.input.map(getWorkPackageId) },
          };
          return updatedItem;
        }
        return item;
      });

      set({ undoStack: updatedUndoStack });
    }

    if (action === 'CLONE_WORK_PACKAGES') {
      const cloneWorkPackageResponse = (data as Record<string, unknown[]>)
        ?.cloneWorkPackages as CloneWorkPackageInput[];
      const cloneWorkPackages = mutation.variables as { input: CloneWorkPackageInput[] } | undefined;
      invariant(cloneWorkPackages?.input !== undefined, 'cloneWorkPackages should exists at this point');
      invariant(Array.isArray(cloneWorkPackageResponse), 'cloneWorkPackageResponse should exists at this point');

      const updatedStack = queue.map((item) => {
        if (item.mutationId === mutation.mutation.mutationId) {
          const updatedItem: CloneWorkPackagesStackItem = {
            ...item,
            action: 'CLONE_WORK_PACKAGES',
            undo: {
              removeWorkPackageVariables: {
                input: cloneWorkPackageResponse.map((wp) => pick(wp, ['id', 'virtual_space_id'])),
              },
            },
            redo: { cloneWorkPackageVariables: cloneWorkPackages },
          };
          return updatedItem;
        }
        return item;
      });

      set({ undoStack: updatedStack });
    }
    set((state) => ({ mutationStatusMap: { ...state.mutationStatusMap, [mutationId]: mutationStatus } }));
  },
  pushUndoAndClearRedo: (mutation, queryCache) => {
    if (mutation.mutation.meta?.undo_action == null || mutation.mutation.meta.undo_action === 'UNDO') {
      return;
    }
    const action = mutation.mutation.meta.undo_action;

    const item: StackItem = {
      mutationId: mutation.mutation.mutationId,
      action,
      timestamp: new Date(),
    };

    if (item.action === 'MOVE_OR_RESIZE_WORK_PACKAGES') {
      const { scheduleViewId } = get();
      const inputVariables = mutation.variables as { input: ResizeInput[] } | undefined;
      invariant(inputVariables?.input !== undefined, 'ResizeInput should exists at this point');

      const antiVariables = generatePreviousStateMoveOrResizeVariables(scheduleViewId, queryCache, inputVariables);
      if (antiVariables == null) {
        return;
      }
      item.undo = { moveOrResizeWorkPackagesVariables: antiVariables };
      item.redo = { moveOrResizeWorkPackagesVariables: inputVariables };
    }

    if (item.action === 'COPY_PASTE_WORK_PACKAGES') {
      const { scheduleViewId } = get();
      const inputVariables = mutation.variables as { input: CopyPasteWorkPackageInput[] } | undefined;
      invariant(inputVariables?.input !== undefined, 'copyPasteWorkPackageInput should exists at this point');

      const variables = generatePreviousCopyPasteVariables(scheduleViewId, queryCache, inputVariables);
      item.undo = {
        moveOrResizeWorkPackageVariables: { input: variables?.update ?? [] },
        removeWorkPackageVariables: { input: variables?.remove ?? [] },
      };
      item.redo = { copyPasteWorkPackageVariables: inputVariables };
    }

    set((state) => {
      const newUndoStack = [...state.undoStack, item];

      if (newUndoStack.length > UNDO_STACK_LENGTH) {
        newUndoStack.shift();
      }
      return { undoStack: newUndoStack, redoStack: [] };
    });
  },
  undoOrRedo: async (actions, queryClient, type) => {
    const currentState = get();

    const isUndo = type === 'undo';
    const item = isUndo ? currentState.undoStack.at(-1) : currentState.redoStack.at(-1);

    if (item == null) {
      return;
    }
    const mutationIsNotComplete = currentState.mutationStatusMap[item.mutationId] !== 'success';

    if (mutationIsNotComplete) {
      return;
    }

    if (item.timestamp < new Date(Date.now() - UNDO_30_MIN_EXPIRY_TIME)) {
      currentState.popUndoStack();
      currentState.popRedoStack();
      return;
    }

    if (currentState.scheduleViewId == null) {
      currentState.popUndoStack();
      currentState.popRedoStack();
      return;
    }

    set({ isUndoing: true });

    const hasChangesVars: WorkPackagesOrCalendarOrSpacesHasChangedByOtherQueryVariables = {
      ids: [],
      scheduleViewId: currentState.scheduleViewId,
      timestamp: item.timestamp.toISOString(),
    };

    try {
      switch (item.action) {
        case 'MOVE_OR_RESIZE_WORK_PACKAGES':
          if (item.undo != null && item.redo != null) {
            const action = actions[item.action];
            hasChangesVars.ids = isUndo
              ? item.undo.moveOrResizeWorkPackagesVariables.input.map((elem) => elem.id)
              : item.redo.moveOrResizeWorkPackagesVariables.input.map((elem) => elem.id);

            const hasChanges = await queryHasChangesByOtherUser(hasChangesVars, queryClient, currentState);
            if (!hasChanges) {
              if (isUndo) {
                await action.undo(item.undo.moveOrResizeWorkPackagesVariables.input, true);
                break;
              }
              await action.redo(item.redo.moveOrResizeWorkPackagesVariables.input, true);
            }
          }
          break;

        case 'CLONE_WORK_PACKAGES':
          if (item.undo != null && item.redo != null) {
            const action = actions[item.action];
            hasChangesVars.ids = isUndo
              ? item.undo.removeWorkPackageVariables.input.map((elem) => elem.id)
              : item.redo.cloneWorkPackageVariables.input.map((elem) => elem.id);

            const hasChanges = await queryHasChangesByOtherUser(hasChangesVars, queryClient, currentState);
            if (!hasChanges) {
              if (isUndo) {
                await action.undo(item.undo.removeWorkPackageVariables);
                break;
              }
              await action.redo(item.redo.cloneWorkPackageVariables);
            }
          }
          break;

        case 'COPY_PASTE_WORK_PACKAGES':
          if (item.undo != null && item.redo != null) {
            const action = actions[item.action];
            hasChangesVars.ids = isUndo
              ? [
                  ...item.undo.moveOrResizeWorkPackageVariables.input.map((elem) => elem.id),
                  ...(item.undo.removeWorkPackageVariables?.input.map((elem) => elem.id) ?? []),
                ]
              : item.redo.copyPasteWorkPackageVariables.input.map((elem) => elem.id);

            const hasChanges = await queryHasChangesByOtherUser(hasChangesVars, queryClient, currentState);
            if (!hasChanges) {
              if (isUndo) {
                if (item.undo.moveOrResizeWorkPackageVariables.input.length > 0) {
                  await action.undo.update(item.undo.moveOrResizeWorkPackageVariables.input, true);
                }
                if (item.undo.removeWorkPackageVariables.input.length > 0) {
                  await action.undo.remove(item.undo.removeWorkPackageVariables);
                }
                break;
              }
              await action.redo(item.redo.copyPasteWorkPackageVariables);
            }
          }
          break;

        case 'REMOVE_WORK_PACKAGES':
          if (item.undo != null && item.redo != null) {
            const action = actions[item.action];
            hasChangesVars.ids = isUndo
              ? item.undo.undoRemoveWorkPackagesVariables.map((elem) => elem.id)
              : item.redo.removeWorkPackageVariables.map((elem) => elem);

            const hasChanges = await queryHasChangesByOtherUser(hasChangesVars, queryClient, currentState);
            if (!hasChanges) {
              if (isUndo) {
                await action.undo(item.undo.undoRemoveWorkPackagesVariables);
                break;
              }
              const result = await action.redo(item.redo.removeWorkPackageVariables);
              item.undo.undoRemoveWorkPackagesVariables = result;
            }
          }
          break;

        default:
          break;
      }
    } catch (e) {
      reportError(e);
      toast(i18n.t('error.unspecific'));
    }

    set({ isUndoing: false });
    if (isUndo) {
      set({ redoStack: [...currentState.redoStack, item] });
      currentState.popUndoStack();
      return;
    }
    set({ undoStack: [...currentState.undoStack, item] });
    currentState.popRedoStack();
  },
  removeStackItem: (mutationId) => {
    set((state) => {
      const newUndoStack = state.undoStack.filter((item) => item.mutationId !== mutationId);
      const newRedoStack = state.redoStack.filter((item) => item.mutationId !== mutationId);
      return { undoStack: newUndoStack, redoStack: newRedoStack };
    });
  },
}));
