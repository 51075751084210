import type React from 'react';
import { NavLink, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { Button, css, IconButton, styled } from '@mui/material';

import { IconChevronRight } from '../Icons/icons';

const navStyles = (props: { isActive?: boolean }) => css`
  color: #f0ecee;
  text-transform: none;
  align-self: stretch;
  height: auto;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  transition: all 150ms ease-in-out;
  padding: 0 1rem;

  :hover {
    background-color: #3f284b;
  }

  ${props.isActive === true &&
  css`
    background-color: #3f284b;
    border-color: #ddd4e1;
  `}
`;

export const StyledNavButton = styled(Button, { shouldForwardProp: isPropValid })<{ isActive?: boolean }>`
  ${navStyles};
  overflow: hidden;

  > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  svg {
    margin-left: 4px;
    margin-right: -8px;
  }
`;

export function NavButton({ to, children, showArrow }: React.PropsWithChildren<{ to: string; showArrow?: boolean }>) {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(to, { relative: 'route' });
  const active = Boolean(useMatch({ path: resolvedPath.pathname }));
  return (
    <StyledNavButton
      onClick={() => {
        navigate(to);
      }}
      isActive={active}
      variant="text"
      title={children?.toString()}
    >
      <span>{children}</span>
      {Boolean(showArrow) && <IconChevronRight />}
    </StyledNavButton>
  );
}

export const IconNavButton = styled(IconButton, { shouldForwardProp: isPropValid })<{ isActive?: boolean }>`
  ${navStyles};
  display: flex;
  align-items: center;
`;

const IconLink = styled(NavLink, { shouldForwardProp: isPropValid })`
  ${navStyles};
  display: flex;
  align-items: center;
`;

export function IconNavLink({ to, children }: Omit<React.ComponentProps<typeof NavLink>, 'to'> & { to: string }) {
  const active = Boolean(useMatch(to));
  return (
    <IconLink to={to} isActive={active}>
      {children}
    </IconLink>
  );
}
