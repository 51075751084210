import { getAuth } from 'firebase/auth';
import { type GraphQLResponse } from 'graphql-request/build/esm/types';

import { logoutLoader } from '~/auth';

const STATIC_HEADERS = {
  'Content-Type': 'application/json',
  'apollographql-client-name': 'web',
  'apollographql-client-version': APP_VERSION,
};

const getAutheticationHeader = async (): Promise<HeadersInit> => {
  const auth = getAuth();
  await auth.authStateReady();

  const user = auth.currentUser;

  if (user === null) {
    return {};
  }

  return {
    Authorization: `Bearer ${await user.getIdToken()}`,
  };
};

const processBody = (query: string, variables?: object) => JSON.stringify({ query, variables });

function isGraphQlResponse<T>(json: unknown): json is GraphQLResponse<T> {
  return Object.prototype.hasOwnProperty.call(json, 'data') || Object.prototype.hasOwnProperty.call(json, 'errors');
}

function isStatusCodeError(httpError: unknown): httpError is { status?: number } | undefined {
  return Object.prototype.hasOwnProperty.call(httpError, 'status');
}

const codeMap = {
  INTERNAL_SERVER_ERROR: 500,
  BAD_USER_INPUT: 400,
  FORBIDDEN: 403,
};

export class FetchError extends Error {
  code: number;

  constructor(response: { status: number; statusText: string }) {
    super(response.statusText);
    this.name = 'FetchError';
    this.code = response.status;
  }
}

export class NoConnectionError extends Error {
  constructor() {
    super('No internet connection');
    this.name = 'NoConnectionError';
  }
}

const handleResponseBody = async <T>(json: unknown) => {
  if (isGraphQlResponse<T>(json)) {
    const error = json.errors?.[0];

    if (error != null) {
      const code = error?.extensions?.code;

      if (code === 'SUBREQUEST_HTTP_ERROR') {
        const httpError = error.extensions.http;
        if (isStatusCodeError(httpError) && httpError?.status === 401) {
          await logoutLoader();
        }
      }

      const mappedCode = codeMap[code as keyof typeof codeMap];

      if (mappedCode != null) {
        throw new FetchError({ status: mappedCode, statusText: error.message });
      }

      throw new Error(typeof code === 'string' ? code : error.message);
    }

    if (json.data != null) {
      return json.data;
    }
  }

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-alert
    alert('Invalid response, expected GraphQLResponse');
  }

  throw new Error('Invalid response, expected GraphQLResponse');
};

export const fetcher =
  <T, V extends object>(query: string, variables?: V, headers?: HeadersInit) =>
  async (metadata = {}): Promise<T> => {
    try {
      const res = await fetch(import.meta.env.VITE_BACKEND_ENDPOINT as string, {
        ...metadata,
        headers: {
          ...headers,
          ...STATIC_HEADERS,
          ...(await getAutheticationHeader()),
        },
        method: 'POST',
        body: processBody(query, variables),
      });

      if (!res.ok) {
        if (res.status === 401) {
          await logoutLoader();
        }

        throw new FetchError(res);
      }

      return await handleResponseBody<T>(await res.json());
    } catch (error) {
      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        throw new NoConnectionError();
      }

      if (error instanceof DOMException && error.message === 'signal is aborted without reason') {
        throw new NoConnectionError();
      }

      throw error;
    }
  };
