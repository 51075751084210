import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, styled, TextField } from '@mui/material';
import { isValidE164PhoneNumber, supportedLanguages } from '@sitedrive/common';
import { useQueryClient } from '@tanstack/react-query';

import { type CurrentUserQuery, useCurrentUserQuery, useUpdateCurrentUserMutation } from '~/@generated/graphql';
import { useInvalidateQueryKeys } from '~/config/queries';
import { usePermissionsLoaded } from '~/permissions';
import { nullableString } from '~/utils/string';

import MultiAvatar from '../MultiAvatar';

const UnstyledInput = styled(TextField)`
  .MuiInputLabel-root,
  .MuiInputLabel-root.Mui-disabled {
    font-weight: 500;
    color: #1c1b1f;
    transform: translate(14px, -3px) scale(0.75);
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiOutlinedInput-input,
  .MuiOutlinedInput-input.Mui-disabled {
    color: #1c1b1f;
    -webkit-text-fill-color: #1c1b1f;
  }
`;

const Avatar = styled(MultiAvatar)`
  width: 10rem;
`;

type User = Omit<
  CurrentUserQuery['current_user'],
  'id' | '__typename' | 'preferences' | 'display_name' | 'display_name_abbrevation'
>;
type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };
type FormValues = NoUndefinedField<User>;
type FormField = keyof FormValues;

export default function useProfileForm({ onSave }: { onSave: () => void }) {
  const permissionsLoaded = usePermissionsLoaded();

  const { data } = useCurrentUserQuery(undefined, { enabled: permissionsLoaded });
  const user = data?.current_user;

  const {
    t: getCommonText,
    i18n: { changeLanguage, exists: keyExists },
  } = useTranslation();
  const { t } = useTranslation('generic', { keyPrefix: 'Header.ProfileEditDialog' });

  const queryClient = useQueryClient();
  const invalidateQueries = useInvalidateQueryKeys();
  const userMutation = useUpdateCurrentUserMutation({
    onSuccess: ({ updateCurrentUser }) => {
      const queryKey = useCurrentUserQuery.getKey();
      queryClient.setQueryData(queryKey, { current_user: updateCurrentUser });
      invalidateQueries(queryKey);
    },
  });

  const apiValues = React.useMemo(
    () => ({
      email: user?.email ?? '',
      first_name: user?.first_name ?? '',
      last_name: user?.last_name ?? '',
      phone_number: user?.phone_number ?? '',
      language: user?.language ?? '',
      timezone: user?.timezone ?? '',
    }),
    [user],
  );

  React.useEffect(() => {
    if (apiValues.language.length > 0) {
      void changeLanguage(apiValues.language);
    }
  }, [changeLanguage, apiValues]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormValues>({ mode: 'onChange', values: apiValues });

  React.useEffect(() => {
    if (apiValues.language.length > 0) {
      void changeLanguage(apiValues.language);
    }
  }, [changeLanguage, apiValues, reset]);

  const handleFormSubmit = handleSubmit(async ({ email: _, ...input }) => {
    await userMutation.mutateAsync({ input: { ...input, phone_number: nullableString(input.phone_number) } });
    onSave();
  });

  const getLabel = (field: FormField) => t(`labels.${field}`);

  const getFieldProps = <TField extends FormField>(field: TField) => {
    const helperKey = `helperText.${field}`;
    const helperText = keyExists(helperKey) ? t(helperKey, { defaultValue: '' }) : undefined;
    return {
      id: field,
      email: field,
      label: getLabel(field),
      error: !(errors[field] == null),
      helperText: errors[field]?.message ?? helperText,
    } as const;
  };

  const getMaxLengthRule = (field: FormField, maxLength: number) => ({
    value: maxLength,
    message: getCommonText('formErrors.maxLength', {
      field: getLabel(field),
      maxLength,
    }),
  });

  const name = [apiValues.first_name + apiValues.last_name].filter(Boolean).join(' ');

  const fields = (
    <Grid container spacing={2}>
      <Grid item xs={12} display="grid" justifyContent="center">
        <Avatar>{name}</Avatar>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="email"
          rules={{ required: true }}
          render={({ field }) => <UnstyledInput disabled fullWidth {...getFieldProps(field.name)} {...field} />}
        />
      </Grid>

      <Grid item xs={12} />

      <Grid item xs={3}>
        <Controller
          control={control}
          name="first_name"
          rules={{ maxLength: getMaxLengthRule('first_name', 50) }}
          render={({ field }) => (
            <TextField
              // NOTE! autoFocus inside dialog does not work in dev env with React.StrictMode enabled,
              // but works in production or with StrictMode disabled: https://github.com/mui/material-ui/issues/33004
              autoFocus
              fullWidth
              {...getFieldProps(field.name)}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={control}
          name="last_name"
          rules={{ maxLength: getMaxLengthRule('last_name', 50) }}
          render={({ field }) => <TextField fullWidth {...getFieldProps(field.name)} {...field} />}
        />
      </Grid>

      <Grid item xs={5}>
        <span />
      </Grid>

      <Grid item xs={5}>
        <Controller
          control={control}
          name="phone_number"
          rules={{
            validate: (phone) => {
              if (phone === '') {
                return true;
              }
              if (isValidE164PhoneNumber(phone)) {
                return true;
              }
              return getCommonText('formErrors.invalidPhoneNumber');
            },
          }}
          render={({ field }) => <TextField fullWidth {...getFieldProps(field.name)} {...field} />}
        />
      </Grid>
      <Grid item xs={7}>
        <span />
      </Grid>

      <Grid item xs={2}>
        <Controller
          control={control}
          name="language"
          rules={{ maxLength: getMaxLengthRule('language', 2) }}
          render={({ field }) => {
            const { helperText, ...fieldProps } = getFieldProps(field.name);
            return (
              <FormControl fullWidth>
                <InputLabel>{fieldProps.label}</InputLabel>
                <Select {...fieldProps} {...field}>
                  {supportedLanguages.map((code) => (
                    <MenuItem key={code} value={code}>
                      {getCommonText('displayNames.language', { value: code })}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{helperText}</FormHelperText>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name="timezone"
          rules={{ maxLength: getMaxLengthRule('timezone', 20) }}
          render={({ field }) => <TextField fullWidth disabled {...getFieldProps(field.name)} {...field} />}
        />
      </Grid>
    </Grid>
  );

  return {
    title: t('title'),
    fields,
    formProps: { onSubmit: handleFormSubmit },
    submitProps: { loading: isSubmitting, loadingPosition: 'center', disabled: !isValid } as const,
  };
}
