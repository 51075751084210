import { assertExists } from './assertions';

export { v4 as uuid4, v5 as uuid5, validate } from 'uuid';

export function getWorkPackageId(task: { id: string; virtual_space_id?: string | null }) {
  if (task.virtual_space_id == null) return task.id;
  return [task.id, task.virtual_space_id].join('_');
}

export function parseWorkPackageId(parsable: string) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [id, virtual_space_id = null] = parsable.split('_');
  return { id: assertExists(id), virtual_space_id };
}
