import { useTranslation } from 'react-i18next';
import { type ActionFunction, Form, type LoaderFunction, redirect } from 'react-router-dom';
import { Box, styled, SvgIcon } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { IconMail } from '~/components/Icons/icons';

import GoogleLogo from '../assets/google.svg?react';
import MicrosoftLogo from '../assets/microsoft.svg?react';
import { AuthButton, AuthText, AuthTitle } from '../auth.components';
import { authError, setOriginalUrl, signInWithGoogle, signInWithMicrosoft, useAsyncError } from '../auth.helpers';
import { authPaths } from '../auth.paths';

const GoogleIcon = styled(SvgIcon)`
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 50%;
  padding: 3px;
  transform: scale(1.2);
`;

const HiddenForm = styled('form')`
  display: none;
`;

const METHOD_FIELD = 'loginMethod';
export const LOGIN_METHODS = {
  Google: {
    value: 'Google',
    backgroundColor: '#4285f4',
    hoverColor: '#76a7fa',
    startIcon: (
      <GoogleIcon>
        <GoogleLogo />
      </GoogleIcon>
    ),
    handler: signInWithGoogle,
  },
  Microsoft: {
    value: 'Microsoft',
    backgroundColor: '#2f2f2f',
    hoverColor: '#757575',
    startIcon: (
      <SvgIcon>
        <MicrosoftLogo />
      </SvgIcon>
    ),
    handler: signInWithMicrosoft,
  },
  Email: {
    value: 'Email',
    startIcon: <IconMail />,
    handler: () => redirect(authPaths.emailLogin),
  },
} as const;

const loader: LoaderFunction = ({ request }) => {
  setOriginalUrl(request.url);
  return null;
};

const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const methodType = formData.get(METHOD_FIELD) as keyof typeof LOGIN_METHODS;
  const method = LOGIN_METHODS[methodType];

  if (method == null) {
    throw new Error(`Invalid login method ${methodType}}`);
  }

  try {
    return await method.handler();
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    return authError(error);
  }
};

const ContentsForm = styled(Form)`
  display: contents;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
`;

function ChooseLoginMethodPage() {
  const { t } = useTranslation('generic', { keyPrefix: 'LoginPage' });

  const { errorMessage, clearError, isError } = useAsyncError();

  const handleHiddenLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const auth = getAuth();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('hidden-email') as string;
    const password = formData.get('hidden-password') as string;
    await signInWithEmailAndPassword(auth, email, password);
  };

  return (
    <>
      <ContentsForm method="post">
        <AuthTitle>{t('title')}</AuthTitle>

        <ButtonContainer>
          {Object.values(LOGIN_METHODS).map((method) => {
            if (errorMessage.existsWithDifferentCredentialsError && method.value !== 'Email') {
              return null;
            }
            return (
              <AuthButton {...method} key={method.value} name={METHOD_FIELD} onClick={clearError}>
                {t(`signInMethods.${method.value}`)}
              </AuthButton>
            );
          })}
        </ButtonContainer>
        {isError && <AuthText sx={{ color: 'red' }}>{errorMessage.message}</AuthText>}
      </ContentsForm>
      <HiddenForm method="post" onSubmit={handleHiddenLogin}>
        <input name="hidden-email" id="hidden-email" />
        <input name="hidden-password" id="hidden-password" />
        <input type="submit" id="hidden-submit" />
      </HiddenForm>
    </>
  );
}

export default {
  loader,
  action,
  element: <ChooseLoginMethodPage />,
};
