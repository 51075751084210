export const en = 'en';
export const fi = 'fi';
export const es = 'es';
export const cs = 'cs';
export const no = 'no';

export const supportedLanguages = [en, fi, es, cs, no] as const;
export const defaultLanguage = en;

export type SupportedLanguage = (typeof supportedLanguages)[number];
