import { useTranslation } from 'react-i18next';
import { useUser } from 'reactfire';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import { colors, SecondaryButton } from '@sitedrive/design-system';

import { AuthButton } from '~/auth/auth.components';
import { linkAccountWithMicrosoft } from '~/auth/auth.helpers';
import { LOGIN_METHODS } from '~/auth/pages/ChooseLoginMethodPage';

import { ContainedIconButton } from '../Buttons';
import SaveButton from '../Buttons/SaveButton';

import useProfileForm from './Header.ProfileEditForm';

const Form = styled('form')`
  display: contents;
`;

export default function ProfileEditDialog({ onClose, open }: { onClose: () => void; open: boolean }) {
  const { t } = useTranslation();
  const profileForm = useProfileForm({ onSave: onClose });

  const user = useUser();
  const microsoftProviderIsLinked =
    user.data?.providerData.some((provider) => provider.providerId === 'microsoft.com') ?? false;

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <Form {...profileForm.formProps}>
        <DialogTitle
          sx={{
            backgroundColor: colors['M3/ref/primary/primary99'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>{profileForm.title}</Typography>
          <ContainedIconButton variant="outlined" size="small" onClick={onClose}>
            <CloseIcon />
          </ContainedIconButton>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors['M3/ref/primary/primary99'], overflow: 'visible' }}>
          {profileForm.fields}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors['M3/ref/primary/primary99'], justifyContent: 'space-between' }}>
          <div>
            {!microsoftProviderIsLinked && (
              <AuthButton
                {...LOGIN_METHODS.Microsoft}
                type="button"
                onClick={async () =>
                  linkAccountWithMicrosoft({
                    success: t('LoginPage.signInMethods.AccountLinkingSuccess'),
                    error: t('error.unspecific'),
                  })
                }
              >
                {t('LoginPage.signInMethods.MicrosoftLinking')}
              </AuthButton>
            )}
          </div>
          <div>
            <SecondaryButton onClick={onClose}>{t('generic.cancel')}</SecondaryButton>
            <SaveButton {...profileForm.submitProps} />
          </div>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
