import type * as React from 'react';
import { useLatestFunction } from '@sitedrive/design-system';
import { MutationCache, QueryCache, QueryClient, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import i18n from '~/locales';
import { useUndoMiddlewareStore } from '~/pages/Schedule/undo/undo.store';
import { FetchError } from '~/utils/fetcher';
import { operationQueryKeys } from '~/utils/queries';
import toast from '~/utils/toast';

import { appConfig } from './config';
import { createIDBPersister } from './IDBPersister';

const queryCacheStaleTime = 1000 * 60 * 60 * 24 * 7; // 7 days
const queryCache = new QueryCache({
  onError: (err) => {
    if (err instanceof Error) {
      toast(i18n.t([`error.${err.message}`, 'error.unspecific'], 'error.unspecific'));
    } else {
      toast(i18n.t('error.unspecific'));
    }
  },
});

export const mutationCache = new MutationCache({
  onMutate: async (variables, mutation) => {
    const state = useUndoMiddlewareStore.getState();
    state.setStatusAndUpdateStack(
      mutation.mutationId,
      'pending',
      { data: undefined, mutation: { mutation, variables } },
      queryCache,
    );
    state.pushUndoAndClearRedo({ mutation, variables }, queryCache);
  },
  onSuccess: async (data, variables, _context, mutation) => {
    const state = useUndoMiddlewareStore.getState();
    state.setStatusAndUpdateStack(
      mutation.mutationId,
      'success',
      { data, mutation: { mutation, variables } },
      queryCache,
    );
  },
  onError: async (error, variables, _context, mutation) => {
    const state = useUndoMiddlewareStore.getState();

    if (error.message.startsWith('Invariant failed: ')) {
      if (!appConfig.isEnv('production')) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }

      reportError(error);
      toast(i18n.t('error.unspecific'));
    }

    state.setStatusAndUpdateStack(
      mutation.mutationId,
      'error',
      { data: undefined, mutation: { mutation, variables } },
      queryCache,
    );
    state.removeStackItem(mutation.mutationId);
  },
});

const nonRetryableCodes = new Set([401, 403, 400, 500]);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: queryCacheStaleTime,
      staleTime: queryCacheStaleTime,
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (error instanceof FetchError && nonRetryableCodes.has(error.code)) {
          return false;
        }
        return failureCount < 3;
      },
    },
    mutations: {
      onError: (err) => {
        if (err instanceof Error) {
          toast(i18n.t([`error.${err.message}`, 'error.unspecific'], 'error.unspecific'));
        } else {
          toast(i18n.t('error.unspecific'));
        }
      },
    },
  },
  queryCache,
  mutationCache,
});

const hideDevTools = appConfig.isE2e;

const cacheQueryKeys = [...operationQueryKeys.DynamicCalendar];

export function QueryProvider({ children }: React.PropsWithChildren) {
  return (
    <PersistQueryClientProvider
      client={client}
      persistOptions={{
        persister: createIDBPersister(),
        maxAge: queryCacheStaleTime,
        dehydrateOptions: {
          shouldDehydrateQuery: ({ queryKey }: { queryKey: string[] }) => {
            if (queryKey[0] === undefined) return false;
            return cacheQueryKeys.includes(queryKey[0] as keyof typeof operationQueryKeys);
          },
        },
      }}
    >
      {children}

      {!hideDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </PersistQueryClientProvider>
  );
}

export function useInvalidateQueryKeys() {
  const queryClient = useQueryClient();

  type QueryKey = Required<NonNullable<Parameters<typeof queryClient.invalidateQueries>[0]>['queryKey']>;
  return useLatestFunction((...queries: QueryKey[]) => {
    queries.forEach((queryKey) => {
      void queryClient.invalidateQueries({ queryKey });
    });
  });
}

export function useRefetchQueryKeys() {
  const queryClient = useQueryClient();

  type QueryKey = Required<NonNullable<Parameters<typeof queryClient.refetchQueries>[0]>['queryKey']>;
  return useLatestFunction((...queries: QueryKey[]) => {
    queries.forEach((queryKey) => {
      void queryClient.refetchQueries({ queryKey });
    });
  });
}
