import type * as React from 'react';
import { Box } from '@mui/material';
import multiavatar from '@multiavatar/multiavatar';

const REGEX = {
  whitespace: /\s+/g,
  urlHexPairs: /%[\dA-F]{2}/g,
  quotes: /"/g,
};

function specialHexEncode(match: string) {
  switch (match) {
    case '%20':
      return ' ';
    case '%3D':
      return '=';
    case '%3A':
      return ':';
    case '%2F':
      return '/';
    default:
      return match.toLowerCase();
  }
}

function svgToDataURI(string: string) {
  return `data:image/svg+xml,${encodeURIComponent(string)
    .trim()
    .replace(REGEX.whitespace, ' ')
    .replace(REGEX.quotes, "'")
    .replace(REGEX.urlHexPairs, specialHexEncode)}`;
}

function avatarizeString(str: string) {
  return svgToDataURI(multiavatar(str));
}

export default function MultiAvatar({
  children,
  ...props
}: Omit<React.ComponentProps<typeof Box>, 'children'> & { children: string }) {
  return <Box component="img" src={avatarizeString(children)} {...props} />;
}

MultiAvatar.avatarizeString = avatarizeString;
