import * as React from 'react';
import { Menu as MuiMenu, MenuItem, type PopoverPosition } from '@mui/material';
import { useLatest } from '@sitedrive/design-system';

import { isElement } from '~/utils/dom';

type MenuProps = React.ComponentProps<typeof MuiMenu>;
type MenuOnClose = NonNullable<MenuProps['onClose']>;
type CloseReason = Parameters<MenuOnClose>[1] | 'closeMenuTriggered';

type ClickEvent = React.MouseEvent<HTMLElement> | MouseEvent;
interface UseMenuProps {
  id: string;
  onClose?: (reason: CloseReason, event?: Event) => void;
  onOpen?: ((event: ClickEvent) => boolean) | ((event: ClickEvent) => void);
  disabled?: boolean;
}

function useMenu({ id, onClose, onOpen, disabled = false }: UseMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
  const [anchorPosition, setAnchorPosition] = React.useState<undefined | PopoverPosition>(undefined);
  const open = disabled ? false : anchorEl != null || anchorPosition != null;

  const latestOnClose = useLatest(onClose);

  return React.useMemo(() => {
    const closeMenu = () => {
      setAnchorEl(null);
      setAnchorPosition(undefined);
      latestOnClose.current?.('closeMenuTriggered');
    };

    const ids = {
      anchor: id,
      root: `${id}-root`,
      list: `${id}-list`,
    };

    const domifiedOpen = open ? 'true' : undefined;

    const close = (event: Event, reason: CloseReason) => {
      setAnchorEl(null);
      setAnchorPosition(undefined);
      latestOnClose.current?.(reason, event);
    };

    const anchorProps = {
      id,
      'aria-controls': open ? id : undefined,
      'aria-haspopup': 'true',
      'aria-expanded': domifiedOpen,
      isActive: open,
      disabled,
      onClick: (event: React.MouseEvent<HTMLElement> | MouseEvent) => {
        if (event.currentTarget instanceof Element) {
          const shouldOpen = onOpen?.(event) ?? true;
          if (shouldOpen) {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }
        }
      },
    } as const;

    const rootProps: MenuProps = {
      id: ids.root,
      anchorEl,
      open,
      onClose: (event: Event, reason) => {
        event.stopPropagation();
        close(event, reason);
      },
      onContextMenu: (event) => {
        event.stopPropagation();

        if (event.altKey && event.metaKey) {
          // Offer possibility to inspect the menu in the browser dev tools
          return;
        }

        event.preventDefault();

        if (isElement(event.target) && event.target.classList.contains('MuiBackdrop-root')) {
          close(event.nativeEvent, 'closeMenuTriggered');
        }
      },
      MenuListProps: { 'aria-labelledby': id, id: ids.list },
      anchorReference: anchorPosition != null ? 'anchorPosition' : 'anchorEl',
      anchorPosition,
    } as const;

    function openToPosition(position: PopoverPosition) {
      setAnchorPosition(position);
    }

    return { anchorProps, rootProps, closeMenu, openToPosition, setAnchorEl, ids, isOpen: open };
  }, [anchorEl, anchorPosition, disabled, id, latestOnClose, onOpen, open]);
}

const Menu = {
  Root: MuiMenu,
  Item: MenuItem,
  useMenu,
};

export default Menu;
