import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Tooltip } from '@mui/material';

import { useCurrentUserQuery } from '~/@generated/graphql';
import { routes } from '~/config/routes';
import { usePermissionsLoaded } from '~/permissions';

import Menu from '../Menu';
import Svg from '../Svg';

import { IconNavButton } from './Header.NavButtons';
import ProfileEditDialog from './Header.ProfileEditDialog';

function UserSettingsMenuComponent() {
  const permissionsLoaded = usePermissionsLoaded();
  const { data } = useCurrentUserQuery(undefined, { enabled: permissionsLoaded });

  const avatarText = data?.current_user.display_name_abbrevation;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const menu = Menu.useMenu({ id: 'settings-menu' });
  const [profileEditDialogOpen, setProfileEditDialogOpen] = React.useState(false);

  return (
    <>
      <Tooltip title={t('Header.UserSettingsMenu.title')}>
        <IconNavButton {...menu.anchorProps} aria-label="avatar-txt">
          <Svg width={24} height={24}>
            <circle cx={12} cy={12} r={10.5} stroke="currentColor" strokeWidth={1.5} />
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              dy="1px"
              fill="currentColor"
              fontSize="0.675rem"
            >
              {avatarText}
            </text>
          </Svg>
        </IconNavButton>
      </Tooltip>

      <Menu.Root {...menu.rootProps}>
        <MenuItem
          onClick={() => {
            setProfileEditDialogOpen(true);
            menu.closeMenu();
          }}
        >
          {t('Header.ProfileEditDialog.title')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(routes.logoutPage());
            menu.closeMenu();
          }}
        >
          {t('generic.logout')}
        </MenuItem>
      </Menu.Root>
      <ProfileEditDialog
        onClose={() => {
          setProfileEditDialogOpen(false);
        }}
        open={profileEditDialogOpen}
      />
    </>
  );
}

export const UserSettingsMenu = React.memo(UserSettingsMenuComponent);
