import React from 'react';
import { useRouteError } from 'react-router';
import Bugsnag, { type BrowserConfig, type OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getAuth } from 'firebase/auth';

import { appConfig } from '~/config/config';
import { getClaritySessionId } from '~/store/clarity/claritySession.store';
import { processError } from '~/utils/error';

const apiKey = import.meta.env.VITE_BUGSNAG_API_KEY;

if (apiKey == null) {
  throw new Error('VITE_BUGSNAG_API_KEY is not set');
}

const releaseStage = appConfig.env === 'dev' ? 'development' : appConfig.env;
const config: BrowserConfig = {
  apiKey,
  releaseStage,
  appVersion: APP_VERSION,
  enabledReleaseStages: ['production', 'staging', 'development'],
  plugins: [new BugsnagPluginReact()],
  onError: async (event, cb) => {
    const { currentUser } = getAuth();
    if (currentUser != null) {
      const [, domain] = currentUser.email?.split('@') ?? [];
      event.setUser(currentUser.uid);
      event.addMetadata('user', { email_domain: domain ?? 'unknown' });
      event.addMetadata('clarity', { session_id: getClaritySessionId() });
    }
    // filter bugsnag errors
    const shouldSend = !event.errors.some(
      (e) =>
        // legacy-migrated read only projects
        (e.errorClass === 'FetchError' && e.errorMessage === 'You are not authorized to perform this action.') ||
        // error resolved in main.tsx
        (e.errorClass === 'TypeError' && e.errorMessage.startsWith('Failed to fetch dynamically imported module')),
    );
    cb(null, shouldSend);
  },
};

if (import.meta.env.MODE === 'test') {
  config.logger = null;
}

Bugsnag.start(config);

export function reportError(error: unknown, onError?: OnErrorCallback) {
  Bugsnag.notify(processError(error), onError);
}

export function useReportRouteError() {
  const error = useRouteError();

  React.useEffect(() => {
    reportError(error);
  }, [error]);
}

const ReactPlugin = Bugsnag.getPlugin('react');

if (ReactPlugin == null) {
  throw new Error('BugsnagPluginReact is not set');
}

const ErrorBoundary = ReactPlugin.createErrorBoundary(React);

export function BugsnagErrorBoundary(props: React.ComponentProps<typeof ErrorBoundary>) {
  return <ErrorBoundary {...props} />;
}
