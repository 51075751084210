import { useEffect, useMemo } from 'react';
import { type NavigateOptions, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useLatestFunction } from '@sitedrive/design-system';

import { getWorkPackageId, parseWorkPackageId } from '~/utils/id';
import { isDefined } from '~/utils/typeGuards';

const routeParams = {
  account: ':accountId',
  project: ':projectId',
  scheduleView: ':scheduleViewId',
  namespace: ':namespaceId',
  site: ':siteId',
  workPackage: ':workPackageCombinedId',
  task: ':taskId',
  metric: ':metricId',
} as const;

const { account, project, scheduleView, namespace, site, task, workPackage, metric } = routeParams;

export const routerRoutes = {
  indexPage: '/',
  logoutPage: '/logout',
  loginPage: '/login',
  legacyLogin: '/legacy',
  authorizedFinishSignIn: '/finish-sign-in',
  schedulePage: `/schedule-views/${scheduleView}`,
  gantt: 'gantt',
  lob: 'lob',
  takt: 'takt',
  scheduleWorkPackageDetails: `work-package-details/${workPackage}`, // Child of schedulePage
  scheduleWorkPackage: `work-packages/${workPackage}`, // Child of schedulePage
  scheduleAdditionalTaskEdit: `work-packages/${workPackage}/additional-tasks/${task}/edit`,
  scheduleAdditionalTaskCreate: `work-packages/${workPackage}/additional-tasks/create`,
  mdm: `/accounts/${account}`,
  mdmNewSite: `/accounts/${account}/site/new`,
  mdmEditSite: `/accounts/${account}/sites/${site}/edit`,
  mdmVirtualSpace: `/accounts/${account}/virtual-space-models/${namespace}`,
  mdmWorkSectionNameSpace: `/accounts/${account}/work-sections`,
  mdmWorkSectionEditor: `/accounts/${account}/work-sections/${namespace}`,
  mdmTagNamespace: `/accounts/${account}/tags`,
  mdmTagsEditor: `/accounts/${account}/tags/${namespace}`,
  mdmSitesSpaceModel: `/accounts/${account}/space-models/${namespace}`,
  mdmUsers: `/accounts/${account}/users`,
  mdmApiUsage: `/accounts/${account}/api-usage`,
  mdmScheduleTemplates: `/accounts/${account}/schedule-templates`,
  adminPage: '/admin',
  adminPageSystemAdmins: '/admin/system-admins',
  sitePage: `/sites/${site}`,
  siteUsers: `/sites/${site}/users`,
  siteTeams: `/sites/${site}/teams`,
  siteSituationalSnapshot: `/sites/${site}/situational-snapshot/${account}`,
  project: `/sites/${site}/projects/${project}`,
  projectSpaceModel: `/sites/${site}/projects/${project}/space-model`,
  projectLocationBreakdowns: `/sites/${site}/projects/${project}/location-breakdowns`,
  projectWorkTime: `/sites/${site}/projects/${project}/work-time`,
  projectSettings: `/sites/${site}/projects/${project}/settings`,
  projectScheduleViews: `/sites/${site}/projects/${project}/schedule-views`,
  projectSituationalSnapshots: `/sites/${site}/projects/${project}/situational-snapshot`,
  projectBillOfQuantities: `/sites/${site}/projects/${project}/bill-of-quantities`,
  projectFinance: `/sites/${site}/projects/${project}/cost-control`,
  projectSituationalSnapshotsInDepth: `/sites/${site}/projects/${project}/situational-snapshot/${metric}`,
  projectDevices: `/sites/${site}/projects/${project}/devices`,
  projectCustomFields: `/sites/${site}/projects/${project}/custom-fields`,
  projectSuppliers: `/sites/${site}/projects/${project}/suppliers`,
} as const;

type QueryParamValue = string | number;

export type QueryParams = Record<string, QueryParamValue | undefined | QueryParamValue[]>;

export function createParamString(params?: QueryParams) {
  if (params == null) {
    return '';
  }

  const entries = Object.entries(params);

  if (entries.length === 0) {
    return '';
  }

  const searchParams = new URLSearchParams();

  entries.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        searchParams.append(key, val.toString());
      });
    } else if (isDefined(value)) {
      searchParams.append(key, isDefined(value) ? value.toString() : '');
    }
  });

  const stringified = searchParams.toString();

  return stringified.length > 0 ? `?${searchParams.toString()}` : '';
}

export const scheduleViewParams = ['gantt', 'lob', 'takt', 'notifications'] as const;
export type ScheduleViewParam = (typeof scheduleViewParams)[number];
const DEFAULT_SCHEDULE_VIEW = 'gantt';

interface WorkPackage {
  id: string;
  virtual_space_id?: string | null;
}

const replaceWorkPackageId = (route: string, wp: WorkPackage) => {
  return route.replace(workPackage, getWorkPackageId(wp));
};

export const routes = {
  indexPage: () => routerRoutes.indexPage,
  logoutPage: () => routerRoutes.logoutPage,
  schedulePage: (scheduleId: string, view?: ScheduleViewParam) =>
    routerRoutes.schedulePage.replace(scheduleView, scheduleId) + createParamString({ view }),
  lobRelative: () => routerRoutes.lob,
  taktRelative: () => routerRoutes.takt,
  scheduleWorkPackageDetails: (scheduleId: string, wp: WorkPackage) =>
    [
      routerRoutes.schedulePage.replace(scheduleView, scheduleId),
      replaceWorkPackageId(routerRoutes.scheduleWorkPackageDetails, wp),
    ].join('/') + window.location.search,
  scheduleWorkPackageDetailsRelative: (wp: WorkPackage) =>
    routerRoutes.scheduleWorkPackageDetails.replace(workPackage, getWorkPackageId(wp)),
  scheduleWorkPackageRelative: (wp: WorkPackage) => replaceWorkPackageId(routerRoutes.scheduleWorkPackage, wp),
  scheduleWorkPackageAbsolute: (scheduleViewId: string, wp: WorkPackage) =>
    `${routerRoutes.schedulePage.replace(scheduleView, scheduleViewId)}/${replaceWorkPackageId(
      routerRoutes.scheduleWorkPackage,
      wp,
    )}`,
  scheduleAdditionalTaskEditRelative: (wp: WorkPackage, taskId: string) =>
    replaceWorkPackageId(routerRoutes.scheduleAdditionalTaskEdit, wp).replace(task, taskId),
  scheduleAdditionalTaskCreateRelative: (wp: WorkPackage) =>
    replaceWorkPackageId(routerRoutes.scheduleAdditionalTaskCreate, wp),
  mdm: (accountId: string) => ({
    root: () => routerRoutes.mdm.replace(account, accountId),
    newSite: () => routerRoutes.mdmNewSite.replace(account, accountId),

    workSections: () => routerRoutes.mdmWorkSectionNameSpace.replace(account, accountId),
    tags: () => routerRoutes.mdmTagNamespace.replace(account, accountId),
    users: () => routerRoutes.mdmUsers.replace(account, accountId),
    mdmApiUsage: () => routerRoutes.mdmApiUsage.replace(account, accountId),
    workSectionEditor: (namespaceId: string) =>
      routerRoutes.mdmWorkSectionEditor.replace(account, accountId).replace(namespace, namespaceId),
    spaceModels: (namespaceId: string) =>
      routerRoutes.mdmSitesSpaceModel.replace(account, accountId).replace(namespace, namespaceId),
    virtualSpaceModels: (namespaceId: string, projectId: string) =>
      routerRoutes.mdmVirtualSpace.replace(account, accountId).replace(namespace, namespaceId) +
      createParamString({ projectId }),
    scheduleTemplates: () => routerRoutes.mdmScheduleTemplates.replace(account, accountId),
  }),

  sitePage: (siteId: string) => ({
    root: () => routerRoutes.sitePage.replace(site, siteId),
    teams: () => routerRoutes.siteTeams.replace(site, siteId),
    users: () => routerRoutes.siteUsers.replace(site, siteId),
    projects: (projectId: string) => ({
      spaceModel: () => routerRoutes.projectSpaceModel.replace(site, siteId).replace(project, projectId),
      settings: () => routerRoutes.projectSettings.replace(site, siteId).replace(project, projectId),
      locationBreakdowns: () =>
        routerRoutes.projectLocationBreakdowns.replace(site, siteId).replace(project, projectId),
      workTime: () => routerRoutes.projectWorkTime.replace(site, siteId).replace(project, projectId),
      scheduleViews: () => routerRoutes.projectScheduleViews.replace(site, siteId).replace(project, projectId),
      billOfQuantities: () => routerRoutes.projectBillOfQuantities.replace(site, siteId).replace(project, projectId),
      finance: () => routerRoutes.projectFinance.replace(site, siteId).replace(project, projectId),
      customFields: () => routerRoutes.projectCustomFields.replace(site, siteId).replace(project, projectId),
      devices: () => routerRoutes.projectDevices.replace(site, siteId).replace(project, projectId),
      suppliers: () => routerRoutes.projectSuppliers.replace(site, siteId).replace(project, projectId),
      situationalSnapshot: () => ({
        root: () => routerRoutes.projectSituationalSnapshots.replace(site, siteId).replace(project, projectId),
        inDepth: (metricId: string) =>
          routerRoutes.projectSituationalSnapshotsInDepth
            .replace(site, siteId)
            .replace(project, projectId)
            .replace(metric, metricId),
      }),
    }),
  }),
  adminPage: () => ({
    root: () => routerRoutes.adminPage,
    systemAdmins: () => routerRoutes.adminPageSystemAdmins,
  }),
};

type ParamName = (typeof routeParams)[keyof typeof routeParams];
type StripColon<T extends string> = T extends `:${infer Value}` ? Value : never;
type ParamWithoutColon = StripColon<ParamName>;

export function useOptionalRouteParam(name: ParamWithoutColon) {
  return useParams()[name.replace(':', '')];
}

export function useRequiredRouteParam(name: ParamWithoutColon) {
  const param = useOptionalRouteParam(name) ?? '';
  if (param.length === 0) {
    throw new Error(`Missing required route param ${name}`);
  }
  return param;
}

export function useWorkPackageIds() {
  return parseWorkPackageId(useRequiredRouteParam('workPackageCombinedId'));
}

const routeSearchParams = {
  project: '?projectId',
  taskIds: '?taskIds',
} as const;

type SearchParamName = (typeof routeSearchParams)[keyof typeof routeSearchParams];
type StripQuestionMark<T extends string> = T extends `?${infer Value}` ? Value : never;
type ParamWithoutQuestionMark = StripQuestionMark<SearchParamName>;

export function useOptionalSearchParam(name: ParamWithoutQuestionMark) {
  return useSearchParams()[0].get(name.replace('?', '')) ?? undefined;
}

export function useRequiredSearchParam(name: ParamWithoutQuestionMark) {
  const param = useOptionalSearchParam(name) ?? '';
  if (param.length === 0) {
    throw new Error(`Missing required search param ${name}`);
  }
  return param;
}

export function useArraySearchParam(name: ParamWithoutQuestionMark) {
  return useSearchParams()[0].getAll(name);
}

function isValidViewName(name: string): name is ScheduleViewParam {
  return scheduleViewParams.includes(name as ScheduleViewParam);
}

const getCurrentScheduleViewTypeKey = (scheduleViewId: string) => `Sitedrive/currentScheduleViewType/${scheduleViewId}`;

export function useScheduleViewParam() {
  const [searchParams, setSearchParams] = useSearchParams();
  const scheduleViewId = useRequiredRouteParam('scheduleViewId');

  const value = useMemo(() => {
    const currentView = ((): ScheduleViewParam => {
      const raw = searchParams.get('view');

      if (raw == null || !isValidViewName(raw)) {
        const localStorageValue = localStorage.getItem(getCurrentScheduleViewTypeKey(scheduleViewId));
        if (localStorageValue != null && isValidViewName(localStorageValue)) {
          return localStorageValue;
        }
        return DEFAULT_SCHEDULE_VIEW;
      }

      return raw;
    })();

    const setCurrentView = (view: ScheduleViewParam) => {
      setSearchParams((prev) => ({ ...prev, view }));
    };

    return {
      views: scheduleViewParams,
      currentView,
      setCurrentView,
    };
  }, [scheduleViewId, searchParams, setSearchParams]);

  useEffect(() => {
    localStorage.setItem(getCurrentScheduleViewTypeKey(scheduleViewId), value.currentView);
  }, [scheduleViewId, value.currentView]);

  return value;
}

export function useNavigateAndKeepParams() {
  const navigate = useNavigate();

  return useLatestFunction(
    (pathname: string, options: NavigateOptions = {}, searchParams: Record<string, string> = {}) => {
      const params = new URLSearchParams(window.location.search);
      const newParams = new URLSearchParams(searchParams ?? '');

      const combined = new URLSearchParams({
        ...Object.fromEntries(params),
        ...newParams,
      }).toString();

      const search = combined.length > 0 ? `?${combined}` : '';

      navigate({ pathname, search }, options);
    },
  );
}
