import type React from 'react';
import { Box, styled } from '@mui/material';

import { HEADER_BAR_HEIGHT_IN_PX } from '../Header/Header';

const Container = styled(Box)`
  padding-top: ${HEADER_BAR_HEIGHT_IN_PX}px;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
`;

export default function PageContainer(props: React.ComponentProps<typeof Container>) {
  return <Container component="main" {...props} />;
}
