import type React from 'react';
import { LoadingButton } from '@mui/lab';
import { styled, Typography } from '@mui/material';

const Title = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  white-space: pre-line;
`;

export function AuthTitle({ children, ...props }: React.ComponentProps<typeof Title>) {
  return (
    <Title variant="h1" {...props}>
      {children}
    </Title>
  );
}

export const AuthText = styled(Typography)`
  font-size: 0.875rem;
  text-align: center;
`;

interface AuthButtonProps extends React.ComponentProps<typeof LoadingButton> {
  backgroundColor?: string;
  hoverColor?: string;
  fontColor?: string;
  value?: string | number;
  name?: string;
}

const StyledLoadingButton = styled(LoadingButton)`
  padding: 0.5rem 1rem;
  border-radius: 28px;

  .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 1rem;
  }
`;

export function AuthButton({
  type = 'submit',
  backgroundColor = '#6750A4',
  fontColor = '#ffffff',
  hoverColor = '#9c8acc',
  variant = 'contained',
  startIcon,
  ...rest
}: AuthButtonProps) {
  return (
    <StyledLoadingButton
      type={type}
      variant={variant}
      endIcon={startIcon == null ? null : <span />}
      startIcon={startIcon}
      {...rest}
      sx={{
        color: fontColor,
        background: backgroundColor,
        justifyContent: startIcon != null ? 'space-between' : 'center',
        ':hover': { background: hoverColor },
      }}
    />
  );
}
