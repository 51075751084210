/* eslint-disable no-restricted-syntax */
export function arrayToRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TObj extends Record<TKey, any>,
  TKey extends string | number,
>(array: TObj[], selector: (obj: TObj) => TKey): Record<string, TObj>;

export function arrayToRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TObj extends Record<TKey, any>,
  TKey extends keyof TObj,
>(array: TObj[], selector: TKey): Record<string, TObj>;

export function arrayToRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TObj extends Record<TKey, any>,
  TKey extends keyof TObj | string | number,
>(array: TObj[], selector: TKey | ((obj: TObj) => TKey)) {
  const result = {} as Record<string, TObj>;

  // eslint-disable-next-line no-restricted-syntax
  for (const item of array) {
    const key = typeof selector === 'function' ? selector(item) : item[selector];
    result[key as string] = item;
  }

  return result;
}

export function idArrayToRecord<TObj extends { id: string }>(array: TObj[]) {
  return arrayToRecord(array, 'id');
}

export function arrayify<T>(val: T | T[]) {
  return Array.isArray(val) ? val : [val];
}

export function uniq<T>(array: T[]) {
  return [...new Set(array)];
}

export function toggleValue<T>(array: T[], value: T): T[] {
  const retVal = [...array];
  if (retVal.includes(value)) retVal.splice(retVal.indexOf(value), 1);
  else retVal.push(value);
  return retVal;
}

export function isEmpty(array: unknown[]) {
  return array == null || array.length === 0;
}

export function isArray<TData>(a: unknown): a is TData[] {
  return Array.isArray(a);
}

/* eslint-disable no-restricted-syntax */
export function groupBy<
  TData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TKey extends keyof TData,
>(array: TData[], selector: (obj: TData) => string): Record<string, TData[]>;

export function groupBy<TData, TKey extends keyof TData>(array: TData[], selector: TKey): Record<string, TData[]>;

export function groupBy<TData, TKey extends keyof TData>(array: TData[], selector: TKey | ((obj: TData) => TKey)) {
  const result: Record<string, TData[]> = {};

  for (const item of array) {
    const key = (typeof selector === 'function' ? selector(item) : item[selector]) as string;

    if (result[key] == null) {
      result[key] = [];
    }
    result[key]?.push(item);
  }

  return result;
}

export function objectify<Data extends string | number, Value>(
  array: Data[],
  value: Value | ((value: Data) => Value),
): Record<string, Value> {
  const result: Record<string, Value> = {};

  for (const item of array) {
    result[item as string] = value instanceof Function ? value(item) : value;
  }

  return result;
}

export function findLeafNodesFromFlatHierarchy<TValue>(array: TValue[], idKey = 'id', parentIdKey = 'parentId') {
  const children = new Set(array.map((item) => item[parentIdKey as keyof TValue]));
  return array.filter((item) => !children.has(item[idKey as keyof TValue]));
}

export function sumArray<T>(array: T[], selector: (item: T) => number, initialValue?: number): number;
export function sumArray(array: number[], initialValue: number): number;
export function sumArray<T>(
  array: T[],
  maybeSelector: ((item: T) => number) | number | undefined,
  maybeInitialValue?: number,
) {
  const selector = typeof maybeSelector === 'function' ? maybeSelector : (item: T): number => item as number;
  const initialValue = typeof maybeSelector === 'function' ? maybeInitialValue : maybeSelector;
  return array.reduce((acc, val) => acc + selector(val), initialValue ?? 0);
}
