import { useEffect, useState, useRef, useCallback } from 'react';

export function useLatest<T>(value: T) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const LOADING_FALLBACK_TIMEOUT = 4000;
const READY_TIMEOUT = 1000;

export type LoadingState = 'idle' | 'loading' | 'ready';

export const useLoadingState = (loading = false) => {
  const [state, setState] = useState<LoadingState>('idle');
  const latestState = useLatest(state);

  useEffect(() => {
    if (loading) {
      setState('loading');
    }
    if (latestState.current === 'loading' && !loading) {
      setState('ready');
    }
  }, [loading, latestState]);

  useEffect(() => {
    let timeout: number | undefined;

    if (state === 'loading') {
      timeout = window.setTimeout(() => {
        setState('idle');
      }, LOADING_FALLBACK_TIMEOUT);
    }
    if (state === 'ready') {
      timeout = window.setTimeout(() => {
        setState('idle');
      }, READY_TIMEOUT);
    }

    return () => {
      if (timeout != null) {
        window.clearTimeout(timeout);
      }
    };
  }, [state]);

  return state;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLatestFunction<T extends (...args: any[]) => any>(fn: T) {
  const latestFunction = useLatest(fn);
  return useCallback((...args: Parameters<T>): ReturnType<T> => latestFunction.current(...args), [latestFunction]);
}
