import { Operations } from '~/@generated/operations';

type QueryOperation = keyof typeof Operations.Query;

export function getOperationQueryKey<Op extends QueryOperation>(operation: Op): [Op] {
  return [Operations.Query[operation] as Op];
}

type OperationKeys = Record<QueryOperation, [QueryOperation]>;

export const operationQueryKeys = Object.values(Operations.Query).reduce<OperationKeys>((acc, operation) => {
  acc[operation as QueryOperation] = [operation as QueryOperation];
  return acc;
  // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
}, {} as OperationKeys);
