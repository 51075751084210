import * as React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useReducedMotion } from '@react-spring/web';
import { theme } from '@sitedrive/design-system';

import useAuthState from './auth/auth.useAuthState';
import PageContainer from './components/Containers/PageContainer';
import LoadingSpinner from './components/Loading';

const UnauthorizedApp = React.lazy(async () => import('./App.Unauthorized'));
const AuthorizedApp = React.lazy(async () => import('./App.Authorized'));

export default function App() {
  const authState = useAuthState();
  useReducedMotion();

  if (authState.isLoading) {
    return (
      <PageContainer>
        <LoadingSpinner />
      </PageContainer>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<LoadingSpinner />}>
        <CssBaseline />
        {authState.signedIn ? <AuthorizedApp /> : <UnauthorizedApp />}
      </React.Suspense>
    </ThemeProvider>
  );
}
