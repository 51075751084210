import { create } from 'zustand';

interface State {
  claritySessionId: string | null;
}

const useClaritySessionStore = create<State>(() => ({
  claritySessionId: null,
}));

export const setClaritySessionId = (id: string | null) => {
  useClaritySessionStore.setState({ claritySessionId: id });
};

export const getClaritySessionId = () => useClaritySessionStore.getState().claritySessionId;
