const validEnvs = ['test', 'local', 'staging', 'production', 'dev'] as const;
type Env = (typeof validEnvs)[number];

function validateEnv(env: unknown): asserts env is Env {
  if (!validEnvs.includes(env as Env)) {
    throw new Error(`Invalid environment: ${env as string}`);
  }
}

const env = import.meta.env.VITE_ENVIRONMENT;
validateEnv(env);

function isEnv(...envs: Env[]) {
  return envs.includes(env);
}

const nonProd = !isEnv('production');
// const local = isEnv('local');

const quantityBetaTestAccounts = new Set([
  '32d5d2e4-bba8-45c0-93f4-dbe14709d92b', // Skanska TEST
  'cd596801-cd37-42b9-8a63-19fe22186ed4', // Fira
  'b5385219-d5d6-4bc0-b5d3-5ff40347c4f6', // Sitedrive Demo
  '71e775d5-c621-4bb2-bc2b-e44826ddf930', // Aro Systems
  'acb0ca47-b7ec-41bf-9805-fc68da7dfc35', // Flow Modules
  '001582e8-6061-410e-895e-bcf50ec64e12', // Skanska FI main account
]);

export const appConfig = {
  env,
  isEnv,
  supportUrl: 'https://support.sitedrive.com',
  isE2e: import.meta.env.MODE === 'test' ? false : localStorage.getItem('e2e') === 'true',
  features: {
    gantt: {
      setColor: !isEnv('staging', 'production'),
    },
    takt: {},
    header: {
      chat: false,
      share: false,
    },
    billOfQuantities: (accountId: string) => nonProd || quantityBetaTestAccounts.has(accountId),
    situationalSnapshot: nonProd,
    finance: nonProd,
    suppliers: (accountId: string) => nonProd || quantityBetaTestAccounts.has(accountId),
    resourceHistogram: nonProd,
  },
};
