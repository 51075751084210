import { reportError } from '~/config/bugsnag';
import { appConfig } from '~/config/config';

const isProduction: boolean = appConfig.isEnv('production');
const prefix = 'Invariant failed';

// Copied from https://github.com/alexreardon/tiny-invariant/blob/master/src/tiny-invariant.ts and added bugsnag reporting

// Throw an error if the condition fails
// Strip out error messages for production
// > Not providing an inline default argument for message as the result is smaller
export function invariant(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: any,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message?: string | (() => string),
): asserts condition {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (condition) {
    return;
  }
  // Condition not passed

  // When not in production we allow the message to pass through
  // *This block will be removed in production builds*

  const provided: string | undefined = typeof message === 'function' ? message() : message;

  // Options:
  // 1. message provided: `${prefix}: ${provided}`
  // 2. message not provided: prefix
  const errorMessage: string = provided != null ? `${prefix}: ${provided}` : prefix;

  reportError(errorMessage);

  // In production we strip the message but still throw
  if (isProduction) {
    throw new Error(prefix);
  }

  throw new Error(errorMessage);
}
